import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const useLocalStore = create(
    persist(
        (set, ) => ({
            hmsTablePageSize: 50,

            setHmsTablePageSize: (pageSize) => {
                set({
                    hmsTablePageSize: pageSize,
                });
            },
        }),
        {
            name: 'globalStore-persistentItems',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => {
                const include = [
                    'hmsTablePageSize',
                ]
                return Object.fromEntries(Object.entries(state).filter(([key]) => include.includes(key)));
            },
        }
    ),
);

export default useLocalStore;