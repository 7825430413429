import { Select } from 'antd';
import useFetchEntities from 'hooks/useFetchEntities';
import { removeAccents } from 'utils/oneLiners';

function VenueSelector({ value, onChange }) {
    const { data: venueData } = useFetchEntities('Venue', { onlyAttributes: ['venueId', 'name'] });

    const venueIds = value?.venueIds;

    const handleVenueChange = (value) => {
        onChange({
            venueIds: value,
        });
    }

    const Placeholder = ({ isLoading, text, mandatory }) => (
        <span>
            {isLoading
                ?
                <span>Loading...</span>
                :
                <>
                    <span>{text}</span>
                    {mandatory && <span className="text-red-500"> *</span>}
                </>
            }
        </span>
    )

    return (
        <div className="grid grid-cols-4 gap-2">
            <Select
                options={venueData?.map(c => ({
                    value: c.venueId,
                    label: c.name,
                }))}
                mode="multiple"
                tokenSeparators={[',']}
                className='md:w-[405px] w-[210px]'
                value={venueData && venueIds}
                disabled={!venueData}
                onChange={handleVenueChange}
                placeholder={<Placeholder isLoading={!venueData} text="Venue" />}
                allowClear
                filterOption={(input, option) => removeAccents(option.label.toLowerCase())?.indexOf(removeAccents(input)?.toLowerCase()) != -1}
            />
        </div>
    )
}

export default VenueSelector