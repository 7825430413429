import { Radio, Spin } from 'antd'
import EntityLogo from 'components/EntityLogo'
import HmsTableWithFilters from 'components/HmsTableWithFilters'
import KeyboardPopconfirm from 'components/KeyboardPopconfirm'
import GameEventModal from 'components/Modals/GameEventModal'
import useEntities from 'hooks/useEntities'
import useLists from 'hooks/useLists'
import { useEffect, useState } from 'react'
import { HiMiniExclamationTriangle } from 'react-icons/hi2'
import extractTextFromJSX from 'utils/extractTextFromJsx'
import icons from 'utils/icons'
import { removeAccents } from 'utils/oneLiners'
import sortGameEvents from 'utils/sortGameEvents'
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const GameEvents = ({ data: initialData, isLoading, reload, lineups, homeTeam, awayTeam }) => {
  const entities = useEntities();
  const { events, ListPeriod } = useLists();
  const [modalData, setModalData] = useState(null);
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState('');
  const [data, setData] = useState(null);
  const [indexedData, setIndexedData] = useState(null);
  const [searchedData, setSearchedData] = useState(null);

  let gameTimeTempSec = 0;
  const periods = ListPeriod
    .map(lp => ({
      name: lp.name,
      short: lp.code,
      duration: lp.duration,
      index: lp.priority,
    }));
  periods.sort((a, b) => a.index - b.index);
  periods.forEach(p => {
    p.startGameTimeSec = `${gameTimeTempSec}`;
    gameTimeTempSec = gameTimeTempSec + p.duration * 60;
    p.endGameTimeSec = `${gameTimeTempSec}`;
  });


  useEffect(() => {
    if (initialData) {

      // magic happens inside sortGameEvents...
      const dataSorted = sortGameEvents(initialData, homeTeam?.teamId, awayTeam?.teamId, periods);

      setData(dataSorted.reverse());
    } else {
      setData(null);
    }
  }, [initialData]);

  const playerColumn = (field) => {
    return {
      width: 200,
      render: (r) => <PlayerColumn player={r[field]} />,
      searchValue: (r) => `${r[field]?.firstName} ${r[field]?.lastName}`,
    }
  }

  const PlayerColumn = ({ player }) => {
    const number = getPlayerNumber(player);
    return (
      <span className="flex items-center gap-3">
        {player?.firstName} {player?.lastName} {number && `(${number})`}
      </span>
    )
  }

  const getPlayerNumber = (player) => {
    return lineups?.find(l => l.playerId == player?.playerId)?.number;
  }


  const gameTimeToSeconds = (gameTime) => {
    const [minutes, seconds] = gameTime.split(':').map(t => parseInt(t));
    return minutes * 60 + seconds;
  }

  const secondsToGameTime = (seconds) => {  
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = seconds % 60;
    return `${minutes}:${secondsRemaining.toString().padStart(2, '0')}`;
  }

  const formatPenaltyTime = (seconds) => {
    const formattedTime = dayjs.duration(seconds, 'seconds').format('mm:ss');
    return formattedTime;
  }

  const calculateEndOfPenaltyText = (penaltyGameTime, duration) => {
    const durationInSeconds = gameTimeToSeconds(duration);
    const penaltyStartGameTimeInSeconds = gameTimeToSeconds(penaltyGameTime);
    const penaltyEndGameTimeInSeconds = penaltyStartGameTimeInSeconds + durationInSeconds; // duration is in minutes 
    const period = periods.find(p => p.startGameTimeSec < penaltyEndGameTimeInSeconds && penaltyEndGameTimeInSeconds <= p.endGameTimeSec );
    if (!period) {
      return 'Trest trvá až do konce zápasu';
    }
    const remainingTimeInPeriod =  period.endGameTimeSec - penaltyEndGameTimeInSeconds;
    return `Trest končí v čase ${formatPenaltyTime(penaltyEndGameTimeInSeconds)} (tj. zbývající čas ${secondsToGameTime(remainingTimeInPeriod)} – ${period.name})`;
  }
    
  const columns = [
    {
      title: 'Period',
      width: 80,
      align: 'center',
      render: (r) => periods.find(p => p.name == r.period)?.short ?? r.period,
      filterType: 'list',
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => a.sortIndex - b.sortIndex,
      },
    },
    {
      title: 'Remaining',
      width: 110,
      align: 'center',
      dataIndex: 'remainingTime',
      sortDirections: [],
    },
    {
      title: 'Time',
      width: 110,
      dataIndex: 'gameTime',
      align: 'center',
      sortDirections: [],
    },
    {
      title: 'Team',
      width: 200,
      render: (r) => {
        let team = null;
        switch (r.entity) {
          case 'GameEventSave':
            team = r.SavedByTeam;
            break;
          case 'GameEventGoal':
            team = r.ScoredByTeam;
            break;
          case 'GameEventPenalty':
            team = r.PenalizedTeam;
            break;
          case 'GameEventInjury':
            team = r.InjuredTeam;
            break;
          case 'GameEventFaceOff':
            team = r.WonByTeam;
            break;
          case 'GameEventPenaltyshot':
            team = r.ExecutedByTeam;
            break;
          default:
            console.warn('Unknown entity', r.entity);
            break;
        }
        return (
          <span className="flex items-center gap-3">
            <span>
              <EntityLogo entity={team} size={32} />
            </span>
            {team?.name}
          </span>
        );
      },
      searchValue: (r) => {
        let team = null;
        switch (r.entity) {
          case 'GameEventSave':
            team = r.SavedByTeam;
            break;
          case 'GameEventGoal':
            team = r.ScoredByTeam;
            break;
          case 'GameEventPenalty':
            team = r.PenalizedTeam;
            break;
          case 'GameEventInjury':
            team = r.InjuredTeam;
            break;
          case 'GameEventFaceOff':
            team = r.WonByTeam;
            break;
          case 'GameEventPenaltyshot':
            team = r.ExecutedByTeam;
            break;
          default:
            console.warn('Unknown entity', r.entity);
            break;
        }
        return team?.name;
      },
      filterType: 'list',
      sortDirections: [],
    },
    {
      title: 'Event',
      width: 150,
      render: (r) => {
        const event = events.find(e => e.entity == r.entity);
        return (
          <span className="inline-block w-[100px] py-[1px] px-1 rounded-[3px] text-center text-white" style={{ backgroundColor: event?.color }}>{event?.name}</span>
        );
      },
      filterType: 'list',
      sortDirections: [],
    },
    {
      title: 'Description',
      // width: 500,
      render: (r) => {
        const playerInfo = (player) => (
          <>číslo <span className="font-bold">{getPlayerNumber(player) ?? '?'} {player?.firstName + ' ' + player?.lastName}</span></>
        )
        switch (r.entity) {
          case 'GameEventSave':
            return <span></span>
          case 'GameEventGoal':
            const effectiveAssist1 = r.AssistedBy1Player ?? r.AssistedBy2Player;
            const effectiveAssist2 = r.AssistedBy1Player && r.AssistedBy2Player;
            return (<span>
              {r.gameTime && 'Čas ' + r.gameTime + ' '}
              Gól týmu <span className="font-bold">{r.ScoredByTeam?.name}</span>{' '}
              {r.ScoredByPlayer && <>vstřelil hráč {playerInfo(r.ScoredByPlayer)}{' '}</>}
              {effectiveAssist1 && <>asistoval hráč {playerInfo(effectiveAssist1)}{' '}</>}
              {effectiveAssist2 && <>a hráč {playerInfo(effectiveAssist2)} </>}
              stav utkání <span className="font-bold">{r.scoreHome}:{r.scoreAway}</span>
            </span>)
          case 'GameEventPenalty':
            return (<span className="flex flex-col">
              <span>
                {r.gameTime && 'Čas ' + r.gameTime + ' '}
                Udělen trest pro tým <span className="font-bold">{r.PenalizedTeam?.name}</span>{' '}
                {r.PenalizedPlayer && <>pro hráče {playerInfo(r.PenalizedPlayer)}{' '}</>}
                {r.duration && <><span className="font-bold">{r.duration}</span>{' '}minut(y){' '}</>}
                {(r.ListPenaltyType || r.ListPenaltySubtype) && <>
                  <span className="font-bold">{[r.ListPenaltyType?.name, r.ListPenaltySubtype?.name].filter(t => t).join(' - ')}</span>
                </>}
              </span>
              {r.duration && <>
                <span className="text-gray-500 italic"> [{calculateEndOfPenaltyText(r.gameTime, r.duration)}]</span>
              </>}
            </span>)
          case 'GameEventInjury':
            return (<span>
              {r.gameTime && 'Čas ' + r.gameTime + ' '}
              Zranění v týmu <span className="font-bold">{r.InjuredTeam?.name}</span>{' '}
              {r.InjuredPlayer && <>hráče {playerInfo(r.InjuredPlayer)}{' '}</>}
              {r.ListInjuryType && <>{r.ListInjuryType?.name}{' '}</>}
            </span>)
          case 'GameEventFaceOff':
            return (<span>
            </span>)
          case 'GameEventPenaltyshot':
            return (<span>
              {r.gameTime && 'Čas ' + r.gameTime + ' '}
              Penalta týmu <span className="font-bold">{r.ExecutedByTeam?.name}</span>{' '}
              {r.ExecutedByPlayer && <>hráče {playerInfo(r.ExecutedByPlayer)}{' '}</>}
            </span>)
          default:
            console.warn('Unknown entity', r.entity);
            break;
        }
      },
      sortDirections: [],
    },
    {
      title: ' ',
      sortDirections: [],
      width: 40,
      fixed: 'right',
      className: 'pointer-events-none',
      render: (record) =>
        <span className="flex flex-nowrap gap-4 pointer-events-none">
          <a className="flex items-center gap-1 pointer-events-auto" onClick={(e) => { e.stopPropagation() }}>
            <span className="flex items-center gap-1">
              <KeyboardPopconfirm
                title={<span className="flex gap-2 items-center text-[1.0rem]"><HiMiniExclamationTriangle className="text-red-500 text-[1.5rem]" /> Confirm delete</span>}
                icon={null}
                onConfirm={() => removeClicked(record)}
                description="Are you sure to delete this event?"
                okText="Delete"
                okButtonProps={{ danger: true }}
                okHotkey="Enter"
                cancelText="No"
                cancelHotkey="Escape"
              >
                {icons.Trash}
              </KeyboardPopconfirm>
            </span>
          </a>
        </span>
    }
  ]

  useEffect(() => {
    const indexedData = data ? data.map(d => {
      let ftIndex = '';
      for (const col of columns) {
        if (col.searchValue) {
          ftIndex += col.searchValue(d) + ' ';
        } else if (col.render) {
          const r = col.render(d);
          if (typeof r == 'string') {
            ftIndex += r + ' ';
          } else {
            ftIndex += extractTextFromJSX(r) + ' ';
          }
        }
      }
      ftIndex = removeAccents(ftIndex.toUpperCase());
      return {
        ...d,
        ftIndex
      };
    }) : null;
    setIndexedData(indexedData);
  }, [data]);

  useEffect(() => {
    if (indexedData) {
      const searchWords = removeAccents(search.trim().toUpperCase()).split(' ').map(w => w.trim());

      const fiteredData =
        ((search.trim() == '')
          ? indexedData
          : indexedData
            .filter(d => {
              for (const searchWord of searchWords) {
                if (d.ftIndex.indexOf(searchWord) == -1) {
                  return false;
                }
              }
              return true;
            })
        )
      setSearchedData(fiteredData[0]?.key ? fiteredData : fiteredData.map((r, idx) => ({ key: idx, ...r })));
    } else {
      setSearchedData(null);
    }
  }, [indexedData, search]);

  const getGameEventsSorted = () => {
    switch (filter) {
      case 'all':
        return searchedData;
      case 'filtered':
        return searchedData?.filter(r => ['GameEventGoal', 'GameEventPenalty', 'GameEventPenaltyshot'].indexOf(r.entity) != -1);
      case 'GameEventSave':
      case 'GameEventGoal':
      case 'GameEventPenalty':
      case 'GameEventInjury':
      case 'GameEventFaceOff':
      case 'GameEventPenaltyshot':
        return searchedData?.filter(r => filter == r.entity);
      default:
        console.warn('Unknown filter', filter);
    }
  }

  const removeClicked = async (record) => {
    const entity = entities[record.entity];
    await entity.delete(record[entity.primaryKey]);
    reload();
  }

  const onRowClickedHandler = (record) => {

    setModalData({
      lineups: lineups,
      event: record,
    });

  }

  const modalCancel = () => {
    setModalData(null);
  }

  const modalOk = async (event) => {
    const entity = entities[event.entity];
    await entity.put(event);
    setModalData(null);
    reload();
  }

  const emptyPlaceholder = data
    ? <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'>There are no game events for this game</span>
    : <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'><Spin delay={0}></Spin></span>;

  return (
    <>
      <GameEventModal data={modalData} open={modalData} onOk={modalOk} onCancel={modalCancel} />

      <HmsTableWithFilters
        size="small"
        extraButtonBarItems={
          <div className="flex items-center gap-3">
            <Radio.Group
              className="select-none"
              size="small"
              buttonStyle="solid"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <Radio.Button value="all">All ({indexedData?.length})</Radio.Button>
              <Radio.Button value="filtered">Filtered ({indexedData?.filter(r => ['GameEventGoal', 'GameEventPenalty', 'GameEventPenaltyshot'].indexOf(r.entity) != -1)?.length})</Radio.Button>
            </Radio.Group>
            <Radio.Group
              className="select-none"
              size="small"
              buttonStyle="solid"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <Radio.Button value="GameEventSave">Save ({indexedData?.filter(r => 'GameEventSave' == r.entity)?.length})</Radio.Button>
              <Radio.Button value="GameEventGoal">Goal ({indexedData?.filter(r => 'GameEventGoal' == r.entity)?.length})</Radio.Button>
              <Radio.Button value="GameEventPenalty">Penalty ({indexedData?.filter(r => 'GameEventPenalty' == r.entity)?.length})</Radio.Button>
              <Radio.Button value="GameEventInjury">Injury ({indexedData?.filter(r => 'GameEventInjury' == r.entity)?.length})</Radio.Button>
              <Radio.Button value="GameEventFaceOff">FaceOff ({indexedData?.filter(r => 'GameEventFaceOff' == r.entity)?.length})</Radio.Button>
              <Radio.Button value="GameEventPenaltyshot">Penaltyshot ({indexedData?.filter(r => 'GameEventPenaltyshot' == r.entity)?.length})</Radio.Button>
            </Radio.Group>
          </div>
        }
        filterStore="state"
        exportEnabled={true}
        exportPrefix={'Game-Events'}
        layoutType='plain'
        onRowClickedLink={(record) => onRowClickedHandler(record)}
        emptyMessage={emptyPlaceholder}
        columns={columns}
        data={getGameEventsSorted()}
      />
    </>
  )
}

export default GameEvents