import useOrganization from 'hooks/useOrganization';
import { useEffect } from 'react';
import useLoginStore from 'store/loginStore';

const NavigateHome = ({ homesByRole, replace }) => {
  const roles = useLoginStore(s => s.roles);
  const { orgNavigate } = useOrganization();

  useEffect(() => {
    const home = homesByRole[roles[0]];
    orgNavigate(home, { replace });
  }, [roles]);
}

export default NavigateHome