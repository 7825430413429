import AppErrorLayout from 'components/Layouts/AppErrorLayout';
import AppLoadingLayout from 'components/Layouts/AppLoadingLayout';
import { ListContext, ListProvider } from 'components/ListsProvider';
import { LoginProvider } from 'components/LoginProvider';
import useOrganization from 'hooks/useOrganization';
import LoginPage from 'pages/LoginPage';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLoginStore from 'store/loginStore';
import { prefixRelativeOrg } from 'utils/prefixRelativeOrg';

const LoginWrapper = ({ roles, children }) => {
    const [wasLoggedIn, setWasLoggedIn] = useState(false);
    const isLoggedIn = useLoginStore(s => s.isLoggedIn);
    const userRoles = useLoginStore(s => s.roles);
    const user = useLoginStore(s => s.user);
    const { organization } = useOrganization();
    const hasRequiredRole = roles ? roles.some(r => userRoles?.indexOf(r) != -1) : true;
    const navigate = useNavigate();
    const { orgId: orgSlug } = useParams();

    // redirect to / after logout - to prevent 403 forbidden after login as another user
    useEffect(() => {
        if (isLoggedIn) {
            setWasLoggedIn(true);

            if (!orgSlug && user?.Organizations?.length > 0) {
                navigate(prefixRelativeOrg(user.Organizations[0].urlSlug));
            }
        } else {
            if (wasLoggedIn) {
                navigate('/');
            }
        }
    }, [isLoggedIn, orgSlug, user]);

    return (
        <LoginProvider>
            {(isLoggedIn === null) && <AppLoadingLayout delay={250} />}
            {(isLoggedIn === false) && <LoginPage />}
            {(isLoggedIn === true && organization == null) &&
                <>
                    {user?.Organizations?.length > 0
                        ? <AppErrorLayout type="NO_ACCESS_TO_ORGANIZATION" />
                        : <AppErrorLayout type="NO_ORGANIZATIONS" />
                    }
                </>
            }
            {(isLoggedIn === true && organization && hasRequiredRole) &&
                <ListProvider>
                    <ListContext.Consumer>
                        {({ isLoading, isError }) => <>
                            {isLoading && <AppLoadingLayout />}
                            {isError && <AppErrorLayout />}
                            {(!isLoading && !isError) && <>{children}</>}
                        </>}
                    </ListContext.Consumer>
                </ListProvider>
            }
            {(isLoggedIn === true && organization && !hasRequiredRole) &&
                <AppErrorLayout type="403" />
            }
        </LoginProvider>
    )
}

export default LoginWrapper