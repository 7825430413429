import useFetchData from 'hooks/useFetchData';
import useOrganization from 'hooks/useOrganization';
import { entitiesDontNeedOrganizationId } from 'utils/apiFunctions';

export default function useFetchDuplicities(entity, filters, onlyAttributes) {

  const { organization } = useOrganization();
  const ORGANIZATION_ID = organization?.organizationId;

  filters = filters ?? {};
  if (entitiesDontNeedOrganizationId.indexOf(entity) == -1) {
    filters = { ...filters, 'organizationId': ORGANIZATION_ID };
  }

  const params = new URLSearchParams();
  params.append('entity', entity);
  params.append('onlyAttributes', onlyAttributes ?? '');

  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined) {
      params.append(key, JSON.stringify(value));
    }
  }

  const { data, status, isLoading, isNotFound, isError, reload } = useFetchData(
    entity
      ? `/admin/duplicities?${params.toString()}`
      : null
  );

  return { data, status, isLoading, isNotFound, isError, reload };
}