import { SendOutlined } from '@ant-design/icons';
import RelationsTable from 'components/RelationsTable';
import useEntities from 'hooks/useEntities';
import useOrganization from 'hooks/useOrganization';
import useConfirmStore from 'store/confirmStore';
import useNotificationStore from 'store/notificationStore';
import { sendInvite } from 'utils/apiFunctions';

const UserInvites = ({ sourceEntityId, data, onChange, sendInviteDisabledReason }) => {
  const entities = useEntities();
  const sourceEntity = entities.User;
  const targetEntity = entities.PasswordResetRequest;
  const showConfirm = useConfirmStore(state => state.showConfirm);
  const { organization } = useOrganization();
  const ORGANIZATION_ID = organization?.organizationId;
  const addMessage = useNotificationStore((state) => state.addMessage);

  return (
    <>
      {sendInviteDisabledReason &&
        <div className="bg-yellow-100 text-orange-500 p-2 border-[1px] border-orange-200 mb-4">
          {sendInviteDisabledReason}
        </div>
      }

      <RelationsTable
        addButton={!sendInviteDisabledReason && {
          label: 'Send Invite',
          icon: <SendOutlined />,
          onClick: async () => {
            showConfirm({
              title: 'Confirm',
              message:
                <div className="mt-4 mb-6">
                  <div>
                    This operation will:
                    <ul className="my-2">
                      <li>Send an invitation email to the user</li>
                      <li>The user will be able to register and login to the system</li>
                    </ul>
                    Are you sure?
                  </div>
                </div>,
              confirmButtonText: 'Yes - Send',
              onOk: async () => {
                const res = await sendInvite({
                  organizationId: ORGANIZATION_ID,
                  userId: sourceEntityId,
                });

                onChange();

                if (res.status == 200) {
                  addMessage({ text: 'Invite sent', type: 'success' });
                } else {
                  addMessage({ text: 'Error creating invite', type: 'error' });
                }
              },
            })
          },
        }}
        data={data}
        sourceEntity={sourceEntity}
        targetEntity={targetEntity}
        sourceEntityId={sourceEntityId}
        emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
      />
    </>
  )
}

export default UserInvites
