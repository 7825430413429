import { Breadcrumb } from 'antd';
import OrgLink from './System/OrgLink';

const Breadcrumbs = ({ items = [] }) => {
    return (
        <Breadcrumb
            className="mb-3 ml-1"
            items={items
                .filter((item) => item.title != null && item.title != '')
                .map((item) => 
                    ({
                        key: item.title,
                        title: 
                            item.link
                                ? <OrgLink to={item.link}><span className="flex items-center gap-[6px]">{item.icon} {item.title}</span></OrgLink>
                                : <span className="flex items-center gap-[6px]">{item.icon} {item.title}</span>
                    })
            )}
        />
    )
}

export default Breadcrumbs