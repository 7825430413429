import { defineWinner } from "../../../utils/oneLiners";
import IGImg from "./IGImg";
import LogoOrTitle from "./LogoOrTitle";

const GameCardTransparent = ({ game, textUnder, children, innerHorizontalWidth='w-[118px]' }, key) => (
  <div key={key} className="z-50 text-center">
    <div className="flex items-center justify-center gap-2 px-4">
      <LogoOrTitle
        logo={game.VenueLogo}
        title={game.VenueName}
        className="h-[50px] w-[50px] text-xl"
      />
      <div
        className={`${defineWinner(game.Score) === 1 && "bg-green-500"} mr-2 h-[80px] w-[12px]`}
      />
      <IGImg
        src={game.HomeTeamLogo}
        alt={game.HomeTeamName}
        className="max-h-[80px] max-w-[80px] rounded-full"
      />
      <div className={`flex h-[100px] ${innerHorizontalWidth} items-center justify-center`}>
        <p className="font-russo-one text-5xl underline decoration-2 underline-offset-2">
          {children}
        </p>
      </div>
      <IGImg
        src={game.AwayTeamLogo}
        alt={game.AwayTeamName}
        className="max-h-[80px] max-w-[80px] rounded-full"
      />
      <div
        className={`${defineWinner(game.Score) === 2 && "bg-green-500"} mr-2 h-[80px] w-[12px]`}
      />

      <div className="w-[60px]" />
    </div>

    <p className="text-gray-200">{textUnder}</p>
  </div>
);

export default GameCardTransparent;
