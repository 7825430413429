import { CloudUploadOutlined, DeleteOutlined, DownloadOutlined, DownOutlined, ExportOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Spin } from 'antd';
import ConfirmDelete from 'components/ConfirmDelete';
import HmsTable from 'components/HmsTable';
import useIsMobile from 'hooks/useIsMobile';
import { useRef, useState } from 'react';
import useLoginStore from 'store/loginStore';
import { deleteAttachment, postAttachment } from 'utils/apiFunctions';
import downloadFile from 'utils/downloadFile';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import icons from 'utils/icons';
import { simulateCtrlClick } from 'utils/simulateCtrlClick';

const formatFileSize = (size) => {
  if (size < 1024) {
    return `${size} B`;
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)} KB`;
  }
  if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;
  }
}

function AttachmentDesktop({ busyText, isMobile, isDragOver, openAttachmentClicked, setDisplayTable, displayTable, downloadAttachmentClicked, deleteAttachmentClicked, attachments, handleUploadClick }) {
  const hasRole = useLoginStore(s => s.hasRole);
  const isAdmin = hasRole('ADMIN');

  return (
    <HmsTable
      layoutType='plain'
      exportEnabled={false}
      onRowClickedLink={(record) => openAttachmentClicked(record)}
      emptyMessage={
        <div className="flex items-center justify-center my-5 gap-3 text-gray-400">
          <CloudUploadOutlined className="text-lg " />
          Click Upload or drag file(s) here
        </div>
      }
      extraButtonBarItems={busyText &&
        <span className="flex items-center gap-2">
          <Spin size="small" />
          <span className="text-blue-500">{busyText}</span>
        </span>
      }
      className={`${isDragOver ? 'border-blue-500 border-[1px] border-dashed rounded-lg' : 'border-transparent border-[1px]'}`}
      buttons={[
        {
          label: 'Upload',
          icon: <UploadOutlined />,
          onClick: handleUploadClick,
        },
      ]}
      columns={[
        {
          title: "File name",
          dataIndex: "fileName",
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: "Size",
          dataIndex: "size",
          width: 150,
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend'],
          render: (size) => formatFileSize(size),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          width: 200,
          defaultSortOrder: 'ascend',
          sortDirections: ['ascend', 'descend'],
          render: (createdAt) => `${formatDate(createdAt)} ${formatTime(createdAt, { seconds: true })}`,
        },
        {
          title: '',
          sortDirections: [],
          width: 50,
          className: 'pointer-events-none',
          fixed: !isMobile ? 'right' : undefined,
          exportable: false,
          render: (record) =>
            <span className="flex flex-nowrap gap-4 pointer-events-none">
              <a className="flex items-center gap-1 pointer-events-auto" onClick={(e) => { e.stopPropagation(); downloadAttachmentClicked(record); }}>
                <span className="flex items-center gap-1">
                  <DownloadOutlined />
                  Download
                </span>
              </a>

              {isAdmin &&
                <a className="flex items-center gap-1 pointer-events-auto hover:text-red-500" onClick={(e) => { e.stopPropagation(); deleteAttachmentClicked(record); }}>
                  <span className="flex items-center gap-1">
                    {icons.Trash}
                    Remove
                  </span>
                </a>
              }
            </span>
        }
      ]}
      data={attachments}
    />
  )
}

function AttachmentsMobile({ isLoading, busyText, isMobile, isDragOver, openAttachmentClicked, setDisplayTable, displayTable, downloadAttachmentClicked, deleteAttachmentClicked, attachments, handleUploadClick }) {
  return (
    <div>
      <div className={`mb-4 flex flex-wrap gap-y-3 gap-x-3 items-center font-normal ${isMobile ? 'mt-1' : ''}`}>
        <Button size="small" icon={<UploadOutlined />} onClick={handleUploadClick}>Upload</Button>
        {busyText &&
          <span className="flex items-center gap-2">
            <Spin size="small" />
            <span className="text-blue-500">Uploading...</span>
          </span>
        }
      </div>

      {isLoading &&
        <div
          className={`border-[1px] rounded-lg p-4 py-6 bg-gray-50 ${isDragOver ? 'border-blue-500 border-dashed' : 'border-gray-200 border-dashed'}`}
        >
          <div className="flex items-center justify-center my-4"><Spin /></div>
        </div>
      }

      {attachments?.length == 0 &&
        <div
          className={`border-[1px] rounded-lg p-4 py-6 bg-gray-50 border-gray-200 border-dashed`}
        >
          <div className="flex items-center justify-center my-5 gap-1 text-gray-400">
            There are no attachments.
          </div>
        </div>
      }

      {!isLoading && attachments?.length > 0 &&
        <div className="flex flex-col gap-3">
          <div className="flex flex-wrap gap-3">
            {attachments?.map((attachment, idx) => (
              <div className="w-full flex justify-between gap-1 items-center border-[1px] border-gray-200 bg-gray-50 rounded-lg p-1 text-sm truncate cursor-default" key={idx} onClick={(e) => e.stopPropagation()}>
                <div className="flex w-full flex-col gap-1 p-2 cursor-pointer rounded-md hover:bg-gray-100" onClick={() => openAttachmentClicked(attachment)}>
                  {attachment.fileName}
                  <div className="text-gray-500 text-xs">
                    {formatFileSize(attachment.size)}
                  </div>
                </div>
                <Dropdown
                  className="cursor-pointer h-full flex items-center justify-center px-3 rounded-md hover:bg-gray-100"
                  trigger={['click']}
                  menu={{
                    items: [
                      {
                        key: 'open',
                        icon: <ExportOutlined />,
                        onClick: (e) => openAttachmentClicked(attachment),
                        label: (
                          <span>
                            Open in new tab
                          </span>
                        ),
                      },
                      {
                        key: 'download',
                        icon: <DownloadOutlined />,
                        onClick: (e) => downloadAttachmentClicked(attachment),
                        label: (
                          <span>
                            Download
                          </span>
                        ),
                      },
                      {
                        type: 'divider',
                      },
                      {
                        key: 'del',
                        icon: <DeleteOutlined />,
                        onClick: (e) => deleteAttachmentClicked(attachment),
                        danger: true,
                        label: (
                          <span>
                            Delete
                          </span>
                        ),
                      },
                    ]
                  }} placement="bottom">
                  <DownOutlined className="text-blue-500" />
                </Dropdown>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}


const EntityAttachments = ({ entity, id, data, reload }) => {
  const fileInputRef = useRef(null);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(false);
  const isLoading = (data == null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadsInProgress, setUploadsInProgress] = useState({});
  const [deletesInProgress, setDeletesInProgress] = useState({});
  const isMobile = useIsMobile();

  const busyText = 
    Object.keys(uploadsInProgress).length > 0 ? 'Uploading ' : '' +
    Object.keys(deletesInProgress).length > 0 ? 'Removing' : '';

  // UPLOAD

  const uploadFile = async (file) => {
    try {
      setUploadsInProgress((value) => { value[file.name] = true; return value; });

      const response = await postAttachment(file, { entity: entity, entityId: id });

      if (response.ok) {
        console.log('ok')
        reload();
      } else {
        console.log('error', response)
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setUploadsInProgress((value) => { delete value[file.name]; return value });
    }
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  }

  const handleFileInputChange = async (e) => {
    for (const file of e.target.files) {
      console.log(file);
      await uploadFile(file);
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
    e.dataTransfer.dropEffect = 'copy';
  }

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log(e.dataTransfer.files);
    const files = Array.from(e.dataTransfer.files);

    for (const file of files) {
      await uploadFile(file);
    }
    setIsDragOver(false);
  }

  // DELETE

  const handleConfirmDelete = async () => {
    setConfirmDeleteVisible(false);
    setDeleteAttachmentId(null);

    try {
      setDeletesInProgress((value) => { value[deleteAttachmentId] = true; return value; });

      const response = await deleteAttachment({ entity: entity, entityId: id, attachmentId: deleteAttachmentId });

      if (response.ok) {
        console.log('ok')
        reload();
      } else {
        console.log('error', response)
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setDeletesInProgress((value) => { delete value[deleteAttachmentId]; return value; });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDeleteVisible(false);
    setDeleteAttachmentId(null);
  };

  // EVENTS

  const openAttachmentClicked = async (attachment) => {
    simulateCtrlClick(attachment.publicUrl);
  }

  const deleteAttachmentClicked = async (attachment) => {
    setConfirmDeleteVisible(true);
    setDeleteAttachmentId(attachment.attachmentId);
  }

  const downloadAttachmentClicked = async (attachment) => {
    try {
      await downloadFile(attachment.publicUrl, attachment.fileName);
    } catch (error) {
      console.error('Download error:', error);
    }
  }

  const attachments = data?.Attachments?.filter((attachment) => attachment.fileName).sort((a, b) => a.fileName.localeCompare(b.fileName));

  return (
    <div>
      <ConfirmDelete visible={confirmDeleteVisible} handleConfirm={handleConfirmDelete} handleCancel={handleCancelDelete} />

      <form>
        <input name="file" type="file" multiple hidden ref={fileInputRef} onChange={handleFileInputChange} />
      </form>

      <div
        className={``}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!isMobile && <AttachmentDesktop {...{ isLoading, busyText, isMobile, isDragOver, openAttachmentClicked, downloadAttachmentClicked, deleteAttachmentClicked, attachments, handleUploadClick }} />}
        {isMobile && <AttachmentsMobile {...{ isLoading, busyText, isMobile, isDragOver, openAttachmentClicked, downloadAttachmentClicked, deleteAttachmentClicked, attachments, handleUploadClick }} />}
      </div>

    </div>
  )
}

export default EntityAttachments;
