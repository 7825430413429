import { ArrowRightOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import useLoginStore from 'store/loginStore';
import { prefixRelativeOrg } from 'utils/prefixRelativeOrg';

const AppErrorLayout = ({ type }) => {
  const logout = useLoginStore(s => s.logout);

  const user = useLoginStore(s => s.user);
  const orgSlug = user?.Organizations?.[0]?.urlSlug;

  let body = null;
  switch (type) {
    case '404':
      body = <>
        <div className="text-center text-[#1677FF] text-lg">404 - Sorry, this page was not found</div>
        <div className="text-center text-[#1677FF] text-md"><ArrowRightOutlined /> <Link to="/" className="underline">Please click here to continue</Link></div>
      </>;
      break;
    case '403':
      body = <>
        <div className="text-center text-[#1677FF] text-lg">Sorry, this page is not available for you</div>
        <div className="text-center text-[#1677FF] text-md"><ArrowRightOutlined /> <Link to="/" className="underline">Please click here to continue</Link></div>
      </>;
      break;
    case 'NO_ORGANIZATIONS':
      body = <>
        <div className="text-center text-red-500 text-lg">Sorry, you don't have access to any organizations.</div>
        <div className="text-center text-[#1677FF] text-md"><ArrowRightOutlined /> <a href="" className="underline" onClick={logout}>Please click here to logout</a></div>
      </>;
      break;
    case 'NO_ACCESS_TO_ORGANIZATION':
      body = <>
        <div className="text-center text-red-500 text-lg">Sorry, you don't have access to this organization.</div>
        {orgSlug && <div className="text-center text-[#1677FF] text-md"><ArrowRightOutlined /> <a href={prefixRelativeOrg(orgSlug)} className="underline">Please click here to go to home page</a></div>}
        <div className="text-center text-[#1677FF] text-md"><ArrowRightOutlined /> <a href="/" className="underline" onClick={logout}>Please click here to logout</a></div>
      </>;
      break;
    default:
      body = <>
        <div className="text-center text-[#1677FF] text-lg">Error loading HMS</div>
        <div className="text-center text-[#1677FF] text-md"><ArrowRightOutlined /> <a href="#" onClick={() => window.location.reload()} className="underline">Please click here to try again</a></div>
      </>;
      break;
  }

  return (
    <Layout className="absolute top-0 left-0 right-0 bottom-0 flex bg-white justify-center items-center">
      <div className="">
        <div className="flex flex-col gap-8">
          <div className="text-center">
            <CloseCircleFilled className="text-red-500 text-3xl" />
          </div>
          {body}
        </div>
      </div>
    </Layout>
  )
}

export default AppErrorLayout