import RelationsTable from 'components/RelationsTable';
import useEntities from 'hooks/useEntities';
import useOrganization from 'hooks/useOrganization';

const PlayerDisciplinary = ({ sourceEntityId, data, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Player;
    const targetEntity = entities.Disciplinary;
    const { orgNavigate } = useOrganization();

    return (
        <div>
            <RelationsTable
                addButton={{
                    label: 'Create disciplinary',
                    onClick: () => orgNavigate(`/disciplinaries/_new_?playerId=${sourceEntityId}`),
                }}
                data={data}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            />
        </div>
    )
}

export default PlayerDisciplinary
