import useOrganization from 'hooks/useOrganization';
import { Link } from 'react-router-dom';
import { prefixRelativeOrg } from 'utils/prefixRelativeOrg';

function OrgLink({ children, to, ...props }) {
    const { organization } = useOrganization();
    const ORGANIZATION_SLUG = organization?.urlSlug;
    const newTo = prefixRelativeOrg(ORGANIZATION_SLUG, to);
    return (
        <Link to={newTo} {...props}>{children}</Link>
    )
}

export default OrgLink