import { useEffect } from 'react';

function usePageTitle(title) {
    useEffect(() => {
        document.title = title || 'HMS';

        return () => {
            document.title = 'HMS';
        };
    });
}

export default usePageTitle