import { Button, Modal } from 'antd';
import TeamRegistrationDialog_AgreeToTerms from 'components/TeamRegistrationDialog/TeamRegistrationDialog_AgreeToTerms';
import TeamRegistrationDialog_Attachments from 'components/TeamRegistrationDialog/TeamRegistrationDialog_Attachments';
import TeamRegistrationDialog_SelectGroups from 'components/TeamRegistrationDialog/TeamRegistrationDialog_SelectGroups';
import TeamRegistrationDialog_SelectSeason from 'components/TeamRegistrationDialog/TeamRegistrationDialog_SelectSeason';
import TeamRegistrationDialog_Specials from 'components/TeamRegistrationDialog/TeamRegistrationDialog_Specials';
import useEntities from 'hooks/useEntities';
import { useEffect, useState } from 'react';
import { postAttachment } from 'utils/apiFunctions';

const TeamRegistrationDialog = ({ teamId, open, onClose, alreadyRegisteredSeasonIds }) => {
  const steps = ['SELECT_SEASON', 'ADD_ONS'];
  const [step, setStep] = useState(steps[0])
  const [busy, setBusy] = useState(false);
  const entities = useEntities();

  const prevClicked = () => {
    const curStepIdx = steps.indexOf(step);
    const prevStepIdx = curStepIdx - 1;
    if (prevStepIdx >= 0) {
      setStep(steps[prevStepIdx]);
    }
  }

  const nextClicked = () => {
    const curStepIdx = steps.indexOf(step);
    const nextStepIdx = curStepIdx + 1;
    if (nextStepIdx < steps.length) {
      setStep(steps[nextStepIdx]);
    }
  }

  const registerClicked = async () => {
    setBusy(true);

    // Step 1: Upload attachments
    const attachments = [];
    if (registration.Attachments) {
      const files = Array.from(registration.Attachments);
      for (const file of files) {
        const response = await postAttachment(file.originFileObj, { entity: 'SeasonRegistration' });
        if (response.ok) {
          const responseJson = await response.json();
          attachments.push(responseJson.attachmentId);
        }
      }
    }

    // Step 2: Create registration (and link with attachments)
    await entities.SeasonRegistration.create({
      teamId: teamId,
      seasonId: registration.seasonId,
      status: 'NEW',
      Groups: registration.groupIds,
      SeasonRegistrationSpecials: (registration.SeasonRegistrationSpecials ?? []).filter(s => s.enabled),
      Attachments: attachments,
    });

    onClose();
  }

  const canContinue = () => {
    if (busy) {
      return false;
    }
    switch (step) {
      case 'SELECT_SEASON':
        return registration.seasonId != null && registration.termsAgreed && registration.feeAgreed && registration.groupIds != null && registration.groupIds.length > 0;
      case 'ADD_ONS':
        return true;
    }
  }

  const ModalFooter = () => (
    <div>
      <Button className={steps.indexOf(step) == 0  ? 'inline-block' : 'hidden'} size="small" onClick={onClose}>Cancel</Button>
      <Button className={steps.indexOf(step) > 0 ? 'inline-block' : 'hidden'} size="small" onClick={prevClicked}>Back</Button>
      <Button className={steps.indexOf(step) < steps.length-1 ? 'inline-block' : 'hidden'} size="small" type="primary" onClick={nextClicked} disabled={!canContinue()}>Next</Button>
      <Button className={steps.indexOf(step) == steps.length-1 ? 'inline-block' : 'hidden'} size="small" type="primary" onClick={registerClicked} disabled={!canContinue()}>Register</Button>
    </div>
  )

  const [registration, setRegistration] = useState({});

  useEffect(() => {
    setRegistration({
      ...registration,
      termsOpen: false,
      termsAgreed: false,
      feeAgreed: false,
      groupIds: [],
    });
  }, [registration.seasonId]);

  return (
    <>
      <Modal
        open={open}
        closeIcon={null}
        title={null}
        footer={<ModalFooter />}
      >
        <div className="mb-5">
          <div className={step == 'SELECT_SEASON' ? 'flex flex-col gap-5' : 'hidden'}>
            <TeamRegistrationDialog_SelectSeason
              registration={registration}
              setRegistration={setRegistration}
              alreadyRegisteredSeasonIds={alreadyRegisteredSeasonIds}
            />

            <div className={registration.seasonId ? 'block' : 'hidden'}>
              <TeamRegistrationDialog_AgreeToTerms
                registration={registration}
                setRegistration={setRegistration}
              />
            </div>

            <div className={registration.seasonId && registration.termsAgreed && registration.feeAgreed ? 'block' : 'hidden'}>
              <TeamRegistrationDialog_SelectGroups
                registration={registration}
                setRegistration={setRegistration}
              />
            </div>
          </div>

          <div className={step == 'ADD_ONS' ? 'block' : 'hidden'}>
            <TeamRegistrationDialog_Specials
              registration={registration}
              setRegistration={setRegistration}
            />

            <TeamRegistrationDialog_Attachments
              registration={registration}
              setRegistration={setRegistration}
            />
          </div>
        </div>

      </Modal>
    </>
  )
}

export default TeamRegistrationDialog
