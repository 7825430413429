import useFetch from 'hooks/useFetch.js';
import { useEffect } from 'react';
import useLoginStore from 'store/loginStore';
import useNotificationStore from 'store/notificationStore';
import { BASE_URL } from 'utils/apiFunctions';

export default function useFetchData(uri) {
    const { data, status, isLoading, isReloading, isNotFound, isError, reload } = useFetch( uri && uri.indexOf('_new_') == -1 ? `${BASE_URL}${uri}` : null);
    const addMessage = useNotificationStore(s => s.addMessage);
    const updateIsLoggedIn = useLoginStore(s => s.updateIsLoggedIn);

    useEffect(() => {
        if (status && status >= 300) {
            addMessage('Error loading data from server');
        }
        if (status == 403) {
            updateIsLoggedIn();     // show login page if the user's session has expired
        }
    }, [status])

    return { data, status, isLoading, isReloading, isNotFound, isError, reload };
}
