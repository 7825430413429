export const PREFIX_PATH = 'org';

export function prefixRelativeOrg(orgSlug, pathAfterPrefix = '') {
    const prefix = `/${PREFIX_PATH}/${orgSlug}`;
    pathAfterPrefix = pathAfterPrefix ?? '';
    if (pathAfterPrefix.startsWith(prefix)) {
        return pathAfterPrefix;
    }
    if (pathAfterPrefix.startsWith('http')) {
        console.log('prefixRelativeOrg is meant only for relative paths, this is absolute url, no action taken:', pathAfterPrefix);
        return pathAfterPrefix;
    }
    if (pathAfterPrefix.startsWith('/')) {
        pathAfterPrefix = pathAfterPrefix.slice(1);
    }

    return `${prefix}/${pathAfterPrefix}`;
}
