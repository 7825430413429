import FormSelect from 'components/Form/FormSelect';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import OrgLink from 'components/System/OrgLink';
import useFetchEntities from 'hooks/useFetchEntities';

const CompetitionSelect = ({ name, label, required }) => {
  const { data, isLoading } = useFetchEntities('Competition', {
    onlyAttributes: [
      'competitionId',
      'name',
    ],
  });

  return (
    <HmsFormContext.Consumer>
      {({ data: formData }) =>
        <FormSelect
          isLoading={isLoading}
          allowClear
          name={name}
          label={label}
          required={required}
          formItemProps={{
            suffix: (
              formData?.competitionId ?
                <OrgLink to={`/competitions/${formData.competitionId}`} target="_blank">
                  <span>Open</span>
                </OrgLink>
              :
              <OrgLink to={null} disabled={true}>
                <span>Open</span>
              </OrgLink>
        )
          }}
          options={
            data
              ?.sort((a, b) => ('' + a.name).localeCompare('' + b.name))
              ?.map(c => ({
                value: c.competitionId,
                searchText: c.name,
                label: c.name,
              }))
          }
        />
      }
    </HmsFormContext.Consumer>
  )
}

export default CompetitionSelect