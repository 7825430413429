import useOrganization from 'hooks/useOrganization';
import { createContext, useEffect, useState } from 'react';
import { BASE_URL } from 'utils/apiFunctions';

const ListContext = createContext({
});

const ListProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [lists, setLists] = useState(null);
    const { organization } = useOrganization();
    const ORGANIZATION_ID = organization?.organizationId;

    const reloadLists = async () => {
        try {
            const response = await fetch(BASE_URL + `/admin/lists?organizationId=${ORGANIZATION_ID}`, {
                credentials: 'include',
            });
            const lists = await response.json();
            console.log('Loaded lists:', lists);
            // await new Promise((r) => setTimeout(r, 5000));
            setLists(lists);
            setIsLoading(false);
            setIsError(false);
        } catch (e) {
            setIsLoading(false);
            setIsError(true);
        }
    }

    useEffect(() => {
        if (ORGANIZATION_ID) {
            reloadLists();
        }
    }, [ORGANIZATION_ID]);

    return (
        <ListContext.Provider value={{ ...lists, isLoading, isError, reloadLists }}>
            {children}
        </ListContext.Provider>
    );
};

export { ListContext, ListProvider };
