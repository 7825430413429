import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Divider, Form, Input, Radio, Select } from 'antd';
import Upload from 'antd/es/upload/Upload';
import EmbedWrapper from 'components/Embeds/EmbedWrapper';
import EntityLogo from 'components/EntityLogo';
import dayjs from 'dayjs';
import useFetchData from 'hooks/useFetchData';
import useLang from 'hooks/useLang';
import useLists from 'hooks/useLists';
import usePageTitle from 'hooks/usePageTitle';
import useRecaptcha from 'hooks/useRecaptcha';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import useNotificationStore from 'store/notificationStore';
import { BASE_URL } from 'utils/apiFunctions';
import extractTextFromJSX from 'utils/extractTextFromJsx';
import { removeAccents } from 'utils/oneLiners';

const CustomDatePicker = ({ value, onChange, ...props }) => {
  const convertedValue = value ? dayjs(value) : null;
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      onChange(formattedDate);
    } else {
      onChange(null);
    }
  };
  return (
    <DatePicker
      allowClear={false}
      value={convertedValue}
      onChange={handleDateChange}
      format="D.M.YYYY"
      {...props}
    />
  );
};

/* Notes:
 *   - virtual scroll in Selects does not work in Ant.Design on mobile (fuck), therefore we can't use virtual scroll:
 *        https://github.com/ant-design/ant-design/issues/26480
 *   - allowClear in Selects does not work in Ant.Design on mobile (shit - button too small to hit), therefore we cannot use it
 */

const EmbedNewPlayerSTART = ({ setState }) => {
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organizationId');
  const lang = searchParams.get('lang') ?? 'cs';
  const [formValues, setFormValues] = React.useState({});
  const isAntFormDesktop = useMediaQuery({ query: '(min-width: 575px)' });
  const { prepareToken } = useRecaptcha();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const addMessage = useNotificationStore((state) => state.addMessage);
  const { FILE_SIZE_LIMIT } = useLists();
  const LANG = useLang(lang);
  usePageTitle('New User - HMS');

  const newUserRequestPost = async (data, recaptchaToken) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data, 'organizationId': organizationId }));
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
    formData.append('recaptchaToken', recaptchaToken);
    const response = await fetch(`${BASE_URL}/public/registerPlayer`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    });
    
    return {
      status: response.status,
      body: await response.json(),
    };
  };

  const { data: lists, isLoading, isError } = useFetchData(`/public/registerPlayerLists` +
    (organizationId ? `?organizationId=${organizationId}` : '') +
    ``
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      addMessage({ text: 'You can only upload JPEG or PNG files', type: 'error' });
      return false;
    }
    if (file.size > FILE_SIZE_LIMIT) {
      addMessage({ text: 'File size limit exceeded: ' + FILE_SIZE_LIMIT / 1024 / 1024 + ' MB', type: 'error' });
      return false;
    }
    setSelectedFile(file);
    return false;   // Prevent automatic upload by returning false
  };

  const handleUploadChange = ({ fileList }) => setFileList(fileList);

  if (!lists) {
    return (
      <EmbedWrapper isLoading={isLoading} isError={isError} lang={lang} />
    );
  }

  let jerseys = [];
  for (var i = 1; i < 100; i++) {
    jerseys.push(i);
  }

  const onValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
  }

  const onFinish = async (values) => {
    if (values.phoneSuffix && values.phoneSuffix != '') {
      values.phone = values.phonePrefix + '-' + values.phoneSuffix;
    }
    delete values.phonePrefix;
    delete values.phoneSuffix;

    console.log('Finish', values)

    const token = await prepareToken();

    setIsSubmitting(true);

    const response = await newUserRequestPost(values, token);

    setIsSubmitting(false);

    console.log(response);

    if (response.status == 200) {
      setState('SUCCESS');
    } else {
      alert('Error submitting form: ' + response.body.message);
    }
  }

  // TODO: This is a temporary fix, until we have multi-lingual lists
  const translatePosition = (label) => {
    switch (label) {
      case 'Gólman':
        return (lang == 'en') ? 'Goalie' : 'Gólman';
      case 'Obránce':
        return (lang == 'en') ? 'Defenceman' : 'Obránce';
      case 'Útočník':
        return (lang == 'en') ? 'Forward' : 'Útočník';
      default:
        return label;
    }
  }

  const phoneCountrySelector = (
    <Form.Item name="phonePrefix" noStyle>
      <Select
        style={{ width: 80 }}
        virtual={isAntFormDesktop}
        options={lists.phoneCountryCodes.map(c => ({ label: c, value: c }))}
      />
    </Form.Item>
  );

  const filterByLabels = (input, option) =>
    removeAccents(option?.value?.toLowerCase()).indexOf(removeAccents(input?.toLowerCase())) == 0 ||
    removeAccents(option?.label?.toLowerCase()).indexOf(removeAccents(input?.toLowerCase())) == 0

  const requiredField = [{ required: true, message: LANG.REQUIRED_FIELD }];

  const isFieldRequired = (field) => {
    return lists.requiredFields.indexOf(field) != -1;
  }

  return (
    <EmbedWrapper isLoading={isLoading} isError={isError}>
      <div className="m-4 mb-16 font-roboto-flex">
        <div className="text-2xl font-bold text-center mt-8 mb-8">{LANG.NEW_PLAYER_TITLE}</div>

        <div className="max-w-[800px] mx-auto">
          <Divider />
        </div>

        <Form
          className="mx-auto"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 12,
          }}
          style={{
            maxWidth: 650,
          }}
          initialValues={{
            countryBirth: lang == 'cs' ? 'CZ' : undefined,
            phonePrefix: lang == 'cs' ? '+420' : undefined,
          }}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Form.Item label={LANG.TEAM} name="teamId" rules={isFieldRequired('teamId') && requiredField}>
            <Select placeholder={LANG.PLEASE_SELECT} allowClear={isAntFormDesktop} showSearch virtual={isAntFormDesktop}
              options={
                lists.teams.map(l => ({
                  label: <span className="flex gap-2 items-center">
                    <EntityLogo entity={l} size={20} />
                    <span>{l.name}</span>
                  </span>,
                  value: l.teamId
                }))
              }
              filterOption={(inputValue, option) => {
                const text = extractTextFromJSX(option.label);
                return removeAccents(text).toLowerCase().includes(removeAccents(inputValue).toLowerCase());
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={isAntFormDesktop && { offset: 8, span: 12 }} name="hasExitingTeam" valuePropName="checked">
            <Checkbox>{LANG.ALREADY_A_MEMBER_OF_A_TEAM}</Checkbox>
          </Form.Item>

          {formValues.hasExitingTeam &&
            <Form.Item name="registrationExistingTeamId" label={LANG.ALREADY_A_MEMBER_OF + ':'} rules={formValues.hasExitingTeam && requiredField}>
              <Select placeholder={LANG.PLEASE_SELECT} allowClear={isAntFormDesktop} showSearch virtual={isAntFormDesktop} options={
                lists.teams.map(l => ({
                  label: <span className="flex gap-2 items-center">
                    <EntityLogo entity={l} size={20} />
                    <span>{l.name}</span>
                  </span>,
                  value: l.teamId
                }))
              }
                filterOption={(inputValue, option) => {
                  const text = extractTextFromJSX(option.label);
                  return removeAccents(text).toLowerCase().includes(removeAccents(inputValue).toLowerCase());
                }}
              />
            </Form.Item>
          }

          <Form.Item label={LANG.FIRST_NAME} name="firstName" rules={isFieldRequired('firstName') && requiredField}>
            <Input />
          </Form.Item>

          <Form.Item label={LANG.LAST_NAME} name="lastName" rules={isFieldRequired('lastName') && requiredField}>
            <Input />
          </Form.Item>

          <Form.Item name="listPlayerLevelId" label={LANG.PLAYER_STATUS} rules={isFieldRequired('listPlayerLevelId') && requiredField}>
            <Select placeholder={LANG.PLEASE_SELECT} allowClear={isAntFormDesktop} showSearch virtual={isAntFormDesktop} options={
              lists.listPlayerLevels.map(l => ({ label: l.name, value: l.listPlayerLevelId }))
            } />
          </Form.Item>

          <Form.Item label={LANG.PLAYER_PROFILE_URL} name="publicProfileUrl" rules={isFieldRequired('publicProfileUrl') && requiredField}>
            <Input addonBefore="https://" />
          </Form.Item>

          <Form.Item label={LANG.JERSEY_NUMBER} name="jerseyNumber" rules={isFieldRequired('jerseyNumber') && requiredField}>
            <Select placeholder={LANG.PLEASE_SELECT} allowClear={isAntFormDesktop} showSearch virtual={isAntFormDesktop} options={
              jerseys.map(j => ({ label: j, value: j }))
            } />
          </Form.Item>

          <Form.Item label={LANG.POSITION} name="listPositionId" rules={isFieldRequired('listPositionId') && requiredField}>
            <Select placeholder={LANG.PLEASE_SELECT} allowClear={isAntFormDesktop} showSearch virtual={isAntFormDesktop} options={
              lists.listPositions.map(l => ({ label: translatePosition(l.name), value: l.listPositionId }))
            } />
          </Form.Item>

          <Form.Item label={LANG.GENDER} name="gender" rules={isFieldRequired('gender') && requiredField}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="MALE">{LANG.MALE}</Radio.Button>
              <Radio.Button value="FEMALE">{LANG.FEMALE}</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={LANG.BIRTHDATE} name="birthday" rules={isFieldRequired('birthday') && requiredField}>
            <CustomDatePicker />
          </Form.Item>

          <Form.Item label={LANG.CITY_OF_BIRTH} name="cityBirth" rules={isFieldRequired('cityBirth') && requiredField}>
            <Input />
          </Form.Item>

          <Form.Item label={LANG.COUNTRY_OF_BIRTH} name="countryBirth" rules={isFieldRequired('countryBirth') && requiredField}>
            <Select placeholder={LANG.PLEASE_SELECT} allowClear={isAntFormDesktop} showSearch virtual={isAntFormDesktop} options={
              lists.countries.map(c => ({ label: lang == 'cs' && c.value == 'CZ' ? 'Česká republika' : lang == 'cs' && c.value == 'SK' ? 'Slovensko' : c.label, value: c.value }))
            } filterOption={filterByLabels} />
          </Form.Item>

          <Form.Item label={LANG.STICK} name="stick" rules={isFieldRequired('stick') && requiredField}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="Left">{LANG.LEFT}</Radio.Button>
              <Radio.Button value="Right">{LANG.RIGHT}</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={LANG.HEIGHT} name="height" rules={isFieldRequired('height') && requiredField}>
            <Input type="number" addonAfter="cm" />
          </Form.Item>

          <Form.Item label={LANG.WEIGHT} name="weight" rules={isFieldRequired('weight') && requiredField}>
            <Input type="number" addonAfter="kg" />
          </Form.Item>

          <Form.Item label={LANG.EMAIL} name="email" rules={isFieldRequired('email') && requiredField}>
            <Input />
          </Form.Item>

          <Form.Item label={LANG.PHONE} name="phoneSuffix" rules={isFieldRequired('phone') && requiredField}>
            <Input addonBefore={phoneCountrySelector} />
          </Form.Item>

          <Form.Item label={LANG.AVATAR} name="image" valuePropName="fileList" getValueFromEvent={e => e.fileList}>
            <Upload
              listType="picture-card"
              beforeUpload={beforeUpload}
              onChange={handleUploadChange}
              fileList={fileList}
            >
              {fileList.length >= 1 ? null : <div><UploadOutlined /> Upload</div>}
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={isAntFormDesktop && { offset: 8, span: 12 }} className={`${!isAntFormDesktop ? 'text-center pt-4' : ''}`}>
            <Button type="primary" htmlType="submit" className="min-w-[115px]" loading={isSubmitting}>
              {!isSubmitting && LANG.SUBMIT}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </EmbedWrapper>
  )
}

export default EmbedNewPlayerSTART