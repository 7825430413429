import { DownOutlined, DownloadOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Card, Dropdown, Space, Tabs } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Breadcrumbs from 'components/Breadcrumbs'
import IG10_GameStreak from 'components/Infographics/IG10_GameStreak'
import IG11_PointLeaders from 'components/Infographics/IG11_PointLeaders'
import IG12_GoalLeaders from 'components/Infographics/IG12_GoalLeaders'
import IG13_AssistLeaders from 'components/Infographics/IG13_AssistLeaders'
import IG14_StarLeaders from 'components/Infographics/IG14_StarLeaders'
import IG15_PenaltyLeaders from 'components/Infographics/IG15_PenaltyLeaders'
import IG16_Award from 'components/Infographics/IG16_Award'
import IG17_TopPlaysOfSelected from 'components/Infographics/IG17_TopPlaysOfSelected'
import IG18_TopPlaysMostPenalties from 'components/Infographics/IG18_TopPlaysMostPenalties'
import IG19_TopPlaysFewestGoals from 'components/Infographics/IG19_TopPlaysFewestGoals'
import IG1_GamesToday from 'components/Infographics/IG1_GamesToday'
import IG2_DayRecap from 'components/Infographics/IG2_DayRecap'
import IG3_WeekRecap from 'components/Infographics/IG3_WeekRecap'
import IG4_YoutubeThumbnail from 'components/Infographics/IG4_YoutubeThumbnail'
import IG5_StandingsByPoints from 'components/Infographics/IG5_StandingsByPoints'
import IG6_StandingsByPIM from 'components/Infographics/IG6_StandingsByPIM'
import IG7_TeamOffenseVsDefense from 'components/Infographics/IG7_TeamOffenseVsDefense'
import IG8_TeamChancesVsScoring from 'components/Infographics/IG8_TeamChancesVsScoring'
import IG9_TeamFaceOffEfficiency from 'components/Infographics/IG9_TeamFaceOffEfficiency'
import dayjs from 'dayjs'
import useSearchParamsMulti from 'hooks/useSearchParamsMulti'
import { domToPng } from 'modern-screenshot'
import React, { createRef } from 'react'
import icons from 'utils/icons'

const Infographics = () => {
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [selectedTab, setSelectedTab] = useSearchParamSingle('selectedTab', null);
  const [showData, setShowData] = React.useState(false);

  const items = [
    {
      key: 'IG1',
      label: '1. Games Today',
      component: IG1_GamesToday,
      exportName: 'GamesToday'
    },
    {
      key: 'IG2',
      label: '2. Day Recap',
      component: IG2_DayRecap,
      exportName: 'DayRecap'
    },
    {
      key: 'IG3',
      label: '3. Week Recap',
      component: IG3_WeekRecap,
      exportName: 'WeekRecap'
    },
    {
      key: 'IG4',
      label: '4. Game YT',
      component: IG4_YoutubeThumbnail,
      exportName: 'GameYT'
    },
    {
      key: 'IG5',
      label: '5. Standings By Points',
      component: IG5_StandingsByPoints,
      exportName: 'StandingsByPoints'
    },
    {
      key: 'IG6',
      label: '6. Standings By PIM',
      component: IG6_StandingsByPIM,
      exportName: 'StandingsByPIM'
    },
    {
      key: 'IG7',
      label: '7. Team Offense Vs Defense',
      component: IG7_TeamOffenseVsDefense,
      exportName: 'TeamOffenseVsDefense'
    },
    {
      key: 'IG8',
      label: '8. Team Chances Vs Scoring',
      component: IG8_TeamChancesVsScoring,
      exportName: 'TeamChancesVsScoring'
    },
    {
      key: 'IG9',
      label: '9. Team Face Off Efficiency',
      component: IG9_TeamFaceOffEfficiency,
      exportName: 'TeamFaceOffEfficiency'
    },
    {
      key: 'IG10',
      label: '10. Game Streak',
      component: IG10_GameStreak,
      exportName: 'GameStreak'
    },
    {
      key: 'IG11',
      label: '11. Point Leaders',
      component: IG11_PointLeaders,
      exportName: 'PointLeaders'
    },
    {
      key: 'IG12',
      label: '12. Goal Leaders',
      component: IG12_GoalLeaders,
      exportName: 'GoalLeaders'
    },
    {
      key: 'IG13',
      label: '13. Assist Leaders',
      component: IG13_AssistLeaders,
      exportName: 'AssistLeaders'
    },
    {
      key: 'IG14',
      label: '14. Star Leaders',
      component: IG14_StarLeaders,
      exportName: 'StarLeaders'
    },
    {
      key: 'IG15',
      label: '15. Penalty Leaders',
      component: IG15_PenaltyLeaders,
      exportName: 'PenaltyLeaders'
    },
    {
      key: 'IG16',
      label: '16. Award',
      component: IG16_Award,
      exportName: 'Award'
    },
    {
      key: 'IG17',
      label: '17. Top Plays Of Selected',
      component: IG17_TopPlaysOfSelected,
      exportName: 'TopPlaysOfSelected'
    },
    {
      key: 'IG18',
      label: '18. Top Plays Most Penalties',
      component: IG18_TopPlaysMostPenalties,
      exportName: 'TopPlaysMostPenalties'
    },
    {
      key: 'IG19',
      label: '19. Top Plays Fewest Goals',
      component: IG19_TopPlaysFewestGoals,
      _exportName: 'TopPlaysFewestGoals'
    }


  ];

  const tabItems = items.map((item) => {
    const ref = createRef();  // generate unique ref for each item - otherwise exporting to PNG could fail
    return {
      key: item.key,
      label: item.label,
      ref,
      children: <>
        <h4>
          {item.label}
        </h4>
        <item.component showData={showData} exportRef={ref} />
      </>,
    };
  });

  const downloadPngClicked = async () => {
    const ref = tabItems.find((item) => item.key === selectedTab)?.ref;
    const item = items.find((item) => item.key === selectedTab);
    const domObject = ref?.current;

    if (!domObject) {
      console.error('Failed to take screenshot (no DOM object)');
      return;
    }

    const options = {
      scale: 4,
      debug: true
    };
    domToPng(domObject, options).then(dataUrl => {
      if (dataUrl?.length < 100) {
        console.error('Failed to take screenshot (too small)');
        return;
      };
      const link = document.createElement('a')
      link.download = `${dayjs().format('YYYY_MM_DD')}_${item.exportName}.png`
      link.href = dataUrl
      link.click()
    })
  }

  // test case for PNG export - might still need it...
  // useInterval(() => {
  //   downloadPngClicked();
  // }, 1000);

  return (
    <Content className="m-3 desktop:m-4 desktop:mr-0">
      <Breadcrumbs items={[{ title: 'Infographics', icon: icons.Infographics }]} />
      <div className="flex gap-5 items-start">
        <Card
          title={
            <div className="flex justify-between items-center">

              <span className="flex gap-4 items-center">
                Infographics generator
              </span>

              <span className="flex items-center gap-2">

                <Button
                  type="primary"
                  size="small"
                  icon={<DownloadOutlined />}
                  onClick={() => downloadPngClicked()}
                >
                  Download PNG
                </Button>

                <Dropdown
                  placement="bottomRight"
                  menu={{
                    items: [{
                      label: 'Show data',
                      onClick: () => setShowData(!showData),
                    }]
                  }}>
                  <Button size="small">
                    <Space>
                      <SettingOutlined />
                      System
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </span>
            </div>

          }
          className="w-full max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]"
          size="small"
        >
          <div className="overflow-scroll">
            <Tabs defaultActiveKey="1"
              activeKey={selectedTab}
              // renderTabBar={(props, DefaultTabBar) => (
              //   // <DefaultTabBar {...props} className="h-[100vh]"/>
              //   <DefaultTabBar {...props} className="h-[400px]"/>
              // )}
              destroyInactiveTabPane={true}
              onChange={(ak) => setSelectedTab(ak)}
              items={tabItems}
              tabPosition='left' />
          </div>
        </Card>
      </div>
    </Content>
  )
}

export default Infographics
