import { DeleteOutlined, FolderOpenOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Select, Spin, Table, Tooltip } from 'antd';
import Confirm from 'components/Confirm';
import EntityLogo from 'components/EntityLogo';
import LinkRelationModal from 'components/Modals/LinkRelationModal';
import PlayerLevelTag from 'components/PlayerLevelTag';
import SegmentedTabs from 'components/SegmentedTabs';
import useEntities from 'hooks/useEntities';
import useFetchEntities from 'hooks/useFetchEntities';
import useIsMobile from 'hooks/useIsMobile';
import useLists from 'hooks/useLists';
import CaptainIcon from 'icons/CaptainIcon';
import GoalieIcon from 'icons/GoalieIcon';
import StarIcon from 'icons/StarIcon';
import { useEffect, useState } from 'react';
import useLoginStore from 'store/loginStore';
import { compareString } from 'utils/comparators';
import icons from 'utils/icons';
import { removeAccents } from 'utils/oneLiners';

const GameLineup = ({ data, readonly }) => {
    const userRoles = useLoginStore(state => state.roles);
    const managedTeams = useLoginStore(state => state.managedTeams);
    const isMobile = useIsMobile();
    const entities = useEntities();
    const { ListPosition } = useLists();
    const { data: lineupData, isLoading: lineupLoading, isReloading: lineupReloading, reload: lineupReload } = useFetchEntities(data?.gameId ? 'Lineup' : null, {
        filters: { gameId: data?.gameId },
        relations: ['ListPosition', 'Player', 'Player>Jerseys', 'Player>ListPositions', 'Player>ListPlayerLevel'],
        extraAttributes: ['StatGoals', 'StatAssists', 'StatPoints', 'StatPenaltyMinutes'],
    });
    const { data: teamHomePlayers, isLoading: homeTeamPlayersLoading } = useFetchEntities(
        data?.HomeTeam?.teamId ? 'Team' : null,
        {
            relations: ['Players', 'Players>ListPositions', 'Players>Jerseys', 'Players>ListPlayerLevel'], filters: { teamId: data?.HomeTeam?.teamId }
        })
    const { data: teamAwayPlayers, isLoading: awayTeamPlayersLoading } = useFetchEntities(
        data?.AwayTeam?.teamId ? 'Team' : null,
        {
            relations: ['Players', 'Players>ListPositions', 'Players>Jerseys', 'Players>ListPlayerLevel'], filters: { teamId: data?.AwayTeam?.teamId }
        })
    const { data: gameStars, isLoading: gameStarsLoading, reload: gameStarsReload } = useFetchEntities('GameStar', { filters: { gameId: data?.gameId } });
    const [addPlayersOpen, setAddPlayersOpen] = useState(false);
    const [addPlayersTeamId, setAddPlayersTeamId] = useState();
    const [isUpdating, setIsUpdating] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(null);
    const isLoading = lineupLoading || lineupReloading || isUpdating || homeTeamPlayersLoading || awayTeamPlayersLoading || gameStarsLoading;

    const playersHome = teamHomePlayers ? teamHomePlayers[0]?.Players : [];
    const playersAway = teamAwayPlayers ? teamAwayPlayers[0]?.Players : [];

    const playersByTeam = Object.fromEntries([
        [data?.HomeTeam?.teamId, playersHome],
        [data?.AwayTeam?.teamId, playersAway]
    ]);

    const usedPlayers = Object.fromEntries((lineupData ?? []).map(l => [l.playerId, true]));

    const getUnusedPlayersFromTeam = (teamId) => {
        const players = playersByTeam[teamId] ?? [];
        return players.filter(p => !usedPlayers[p.playerId]);
    }

    const unusedActivePlayersByTeam = {
        [data?.HomeTeam?.teamId]: getUnusedPlayersFromTeam(data?.HomeTeam?.teamId).filter(p => !p.status || p.status == 'ACTIVE'),
        [data?.AwayTeam?.teamId]: getUnusedPlayersFromTeam(data?.AwayTeam?.teamId).filter(p => !p.status || p.status == 'ACTIVE'),
    }

    const getLineupByTeam = (teamId) => {
        return lineupData?.filter(l => l.teamId == teamId) ?? [];
    }

    const lineupByTeam = {
        [data?.HomeTeam?.teamId]: getLineupByTeam(data?.HomeTeam?.teamId),
        [data?.AwayTeam?.teamId]: getLineupByTeam(data?.AwayTeam?.teamId),
    }

    useEffect(() => {
        if (lineupData) {
            setIsUpdating(false);
        }
    }, [lineupData]);

    const addPlayers = (t) => {
        setAddPlayersTeamId(t.teamId);
        setAddPlayersOpen(true);
    }

    const addAllPlayers = async (t) => {
        if (!confirmOpen) { // prevent fast pressing of enter to cause modal to flicker (pressing enter activates the Add all button before the moda becomes fully visible)        
            setConfirmOpen({
                message: `Are you sure do you want to add ${unusedActivePlayersByTeam[t.teamId]?.length} more ${unusedActivePlayersByTeam[t.teamId]?.length == 1 ? 'player' : 'players'}?`,
                confirmButtonText: `Add ${unusedActivePlayersByTeam[t.teamId]?.length} ${unusedActivePlayersByTeam[t.teamId]?.length == 1 ? 'player' : 'players'}`,
                onOk: () => addAllPlayersOk(t),
                onCancel: () => addAllPlayersCancel(),
            });
        }
    }

    const addAllPlayersOk = async (t) => {
        setIsUpdating(true);
        setConfirmOpen(null);
        const players = unusedActivePlayersByTeam[t.teamId] ?? [];
        const records = players.map(player => ({
            name: '' + getTeamName(t.teamId) + ' - ' + player.Jerseys[0]?.number ?? '??',
            number: player.Jerseys?.find(j => j.teamId == t.teamId)?.number ?? null,
            playerId: player.playerId,
            listPositionId: player.ListPositions[0]?.listPositionId,
            gameId: data.gameId,
            teamId: t.teamId
        }));
        await entities.Lineup.bulkCreate(records);
        lineupReload();
    }

    const addAllPlayersCancel = () => {
        setConfirmOpen(null);
    }

    const addPlayersOk = async (playerIds, playerRecords) => {
        setIsUpdating(true);
        setAddPlayersOpen(false);
        setAddPlayersTeamId(null);
        for (const player of playerRecords) {
            console.log(player);

            await entities.Lineup.create({
                name: '' + getTeamName(addPlayersTeamId) + ' - ' + player.Jerseys[0]?.number ?? '??',
                number: player.Jerseys?.find(j => j.teamId == addPlayersTeamId)?.number ?? null,
                playerId: player.playerId,
                listPositionId: player.ListPositions[0]?.listPositionId,
                gameId: data.gameId,
                teamId: addPlayersTeamId
            })
        }
        lineupReload();
    }

    const addPlayersCancel = () => {
        setAddPlayersOpen(false);
        setAddPlayersTeamId(null);
    }

    const removeClicked = async (r) => {
        if (!confirmOpen) { // prevent fast pressing of enter to cause modal to flicker (pressing enter activates the Remove button before the moda becomes fully visible)
            setConfirmOpen({
                message: <span>Are you sure do you want to remove <span className="font-bold whitespace-nowrap">{r.Player?.firstName} {r.Player?.lastName}?</span></span>,
                confirmButtonText: "Delete",
                confirmButtonType: "danger",
                onOk: () => confirmRemoveOk(r.lineupId),
                onCancel: confirmRemoveCencel,
            })
        }
    }

    const confirmRemoveOk = async (lineupId) => {
        setIsUpdating(true);
        setConfirmOpen(null);
        await entities.Lineup.delete(lineupId);
        lineupReload();
    }

    const confirmRemoveCencel = () => {
        setConfirmOpen(null);
    }

    const removeAllPlayers = async (t) => {
        if (!confirmOpen) { // prevent fast pressing of enter to cause modal to flicker (pressing enter activates the Remove all button before the moda becomes fully visible)
            setConfirmOpen({
                message: "Are you sure do you want to remove all players?",
                confirmButtonText: "Remove all players",
                confirmButtonType: "danger",
                onOk: () => removeAllOk(t.teamId),
                onCancel: removeAllCancel,
            })
        }
    }

    const removeAllOk = async (teamId) => {
        setIsUpdating(true);
        setConfirmOpen(null);
        const lineupRecords = lineupByTeam[teamId] ?? [];
        const ids = [];
        for (const lineup of lineupRecords) {
            ids.push(lineup.lineupId);
        }
        await entities.Lineup.bulkDelete(ids);
        lineupReload();
    }

    const removeAllCancel = () => {
        setConfirmOpen(null);
    }

    const changeJerseyNumber = async (r, number) => {
        setIsUpdating(true);
        await entities.Lineup.patch(r.lineupId, {
            number: number !== undefined ? number : null,
            name: '' + getTeamName(r.teamId) + ' - ' + (number !== undefined ? number : '??')
        });
        lineupReload();
    }

    const getTeamName = (teamId) => {
        if (data?.HomeTeam?.teamId == teamId) {
            return data?.HomeTeam?.name;
        }
        if (data?.AwayTeam?.teamId == teamId) {
            return data?.AwayTeam?.name;
        }
    }

    const changeIsCaptain = async (r, value) => {
        setIsUpdating(true);
        if (value == true) {
            const sameTeamLineup = lineupData.filter(l => l.teamId == r.teamId);
            const currentCaptains = sameTeamLineup.filter(l => l.isCaptain == true);
            for (const currentCaptain of currentCaptains) {
                await entities.Lineup.patch(currentCaptain.lineupId, { isCaptain: false });
            }
        }
        await entities.Lineup.patch(r.lineupId, { isCaptain: value });
        lineupReload();
    }

    const changePosition = async (r, listPositionId) => {
        setIsUpdating(true);
        await entities.Lineup.patch(r.lineupId, { listPositionId: listPositionId !== undefined ? listPositionId : null })
        lineupReload();
    }

    const changeIsGameStar = async (r, value) => {
        setIsUpdating(true);
        if (value == true) {
            const gs = await entities.GameStar.create({
                playerId: r.playerId,
                teamId: r.teamId,
                gameId: data.gameId,
            });
            gameStarsReload();
        } else {
            const gs = gameStars.find(gs => gs.playerId == r.playerId && gs.teamId == r.teamId);
            if (gs) {
                await entities.GameStar.delete(gs.gameStarId);
                gameStarsReload();
            }
        }
        setIsUpdating(false);
    }

    const isGameStar = (r) => {
        return gameStars?.find(gs => gs.playerId == r.playerId && gs.teamId == r.teamId) != null;
    }

    const getOtherJerseyNumbers = (jerseys) => {
        let result = [];
        for (var i = 1; i < 100; i++) {
            if (jerseys.indexOf(i) == -1) {
                result.push(i);
            }
        }
        return result;
    }

    const getOtherPositions = (positions) => {
        const usedPosition = positions?.map(p => p.listPositionId) ?? [];
        let result = [];
        for (const position of ListPosition) {
            if (usedPosition.indexOf(position.listPositionId) == -1) {
                result.push(position);
            }
        }
        return result;
    }

    const SuppressZero = ({ value }) => {
        return (<span className={value == 0 ? 'text-gray-300' : ''}>{value == 0 ? '0' : value}</span>);
    }

    const PositionSelect = ({ r, className }) => {
        const isArchived = r?.ListPosition?.isDeleted == true;
        const options = [
            ...(isMobile && r.listPositionId != null ? [
                {
                    label: 'Clear',
                    options: [
                        {
                            value: null,
                            label: 'I don\'t know',
                        }
                    ],
                },
            ] : []),
            ...(r?.Player?.ListPositions && r?.Player?.ListPositions.length > 0 ? [
                {
                    label: 'Preferred',
                    options: (r?.Player?.ListPositions ?? [])
                        .filter(pt => !pt.isDeleted)
                        .map(pt => ({
                            label: pt.name,
                            value: pt.listPositionId,
                        }))
                }] : []),
            {
                label: (r?.Player?.ListPositions && r?.Player?.ListPositions.length > 0) ? 'Other' : 'All',
                options: getOtherPositions(r?.Player?.ListPositions)
                    .filter(pt => !pt.isDeleted)
                    .map(pt => ({
                        label: pt.name,
                        value: pt.listPositionId,
                    }))
            },
            ...(isArchived ? [
                {
                    label: 'Archived',
                    options: [{
                        label: <span className="text-gray-400 flex gap-2">{r?.ListPosition?.name}<Tooltip title="Archived" className="cursor-pointer"><FolderOpenOutlined /></Tooltip></span>,
                        value: r?.ListPosition?.listPositionId,
                    }]
                }
            ] : [])
        ];

        return (
            <Select
                className={className}
                allowClear={!isMobile}
                showSearch={!isMobile}
                filterOption={(input, option) => removeAccents(option.label.toLowerCase())?.indexOf(removeAccents(input)?.toLowerCase()) != -1}
                options={options}
                value={r.listPositionId}
                onChange={(value) => changePosition(r, value)}
                disabled={readonly || isUpdating}
            />
        )
    }

    const JerseySelect = ({ r, className }) => {
        return (
            <Select
                className={className}
                allowClear={!isMobile}
                showSearch={!isMobile}
                options={
                    [
                        ...(isMobile && r.number != null ? [
                            {
                                label: 'Clear',
                                options: [
                                    {
                                        value: null,
                                        label: 'I don\'t know',
                                    }
                                ],
                            },
                        ] : []),
                        ...(((r?.Player?.Jerseys?.filter(j => j.teamId == r.teamId)?.map(j => j.number) ?? []).length > 0) ?
                            [
                                {
                                    label: 'Preferred',
                                    options: (r?.Player?.Jerseys?.filter(j => j.teamId == r.teamId)?.map(j => j.number) ?? [])
                                        .map(number => ({
                                            value: number,
                                            label: number,
                                        }))
                                }
                            ] : []),
                        {
                            label: ((r?.Player?.Jerseys?.filter(j => j.teamId == r.teamId)?.map(j => j.number) ?? []).length > 0) ? 'Other' : 'All',
                            options: getOtherJerseyNumbers((r?.Player?.Jerseys?.filter(j => j.teamId == r.teamId)?.map(j => j.number) ?? []))
                                .map(number => ({
                                    value: number,
                                    label: number,
                                }))
                        },
                    ]
                }
                value={r.number}
                onChange={(number) => changeJerseyNumber(r, number)}
                disabled={readonly || isUpdating}
            />
        )
    }

    const GoalieCheckbox = ({ r, children }) => (
        <Checkbox
            checked={r.ListPosition?.isGoalie == true}
            disabled={r.ListPosition?.isGoalie != true}
        >{children}</Checkbox>
    )

    const CaptainCheckbox = ({ r, children }) => (
        <Checkbox
            checked={r.isCaptain == true}
            onChange={(e) => changeIsCaptain(r, e.target.checked)}
            disabled={readonly || isUpdating}
        >{children}</Checkbox>
    )

    const StarCheckbox = ({ r, children }) => (
        <Checkbox
            checked={isGameStar(r)}
            onChange={(e) => changeIsGameStar(r, e.target.checked)}
            disabled={readonly || isUpdating}
        >{children}</Checkbox>
    )

    const LineupTableDesktop = ({ team: t }) => (
        <div>
            <Table
                size="small"
                showSorterTooltip={false}
                locale={{ emptyText: 
                    isLoading ?
                        <span className='h-12 w-full flex justify-center items-center'>Loading...</span>
                    :
                        <span className='h-12 w-full flex justify-center items-center'>There are no players in this lineup.</span>
                }}
                columns={[
                    ...(userRoles?.includes('ADMIN') ? [
                        {
                            title: 'Player',
                            render: (r) =>
                                <a href={entities.Player.entityUrl(r)} rel="noreferrer" target="_blank">
                                    <span className="flex items-center gap-3 min-w-[150px]">
                                        <span>
                                            <EntityLogo entity={r?.Player} size={32} />
                                        </span>
                                        {r?.Player?.firstName + ' ' + r?.Player?.lastName}
                                    </span>
                                </a>,
                            sorter: (a, b) => ('' + a?.Player?.lastName + ' ' + a?.Player?.firstName).localeCompare('' + b?.Player?.lastName + ' ' + b?.Player?.firstName),
                            defaultSortOrder: 'ascend',
                            sortDirections: ['ascend'],
                        },
                    ] : [
                        {
                            title: 'Player',
                            render: (r) =>
                                <span className="flex items-center gap-3 min-w-[150px]">
                                    <span>
                                        <EntityLogo entity={r?.Player} size={32} />
                                    </span>
                                    {r?.Player?.firstName + ' ' + r?.Player?.lastName}
                                </span>,
                            sorter: (a, b) => ('' + a?.Player?.lastName + ' ' + a?.Player?.firstName).localeCompare('' + b?.Player?.lastName + ' ' + b?.Player?.firstName),
                            defaultSortOrder: 'ascend',
                            sortDirections: ['ascend'],
                        },
                    ]),
                    {
                        title: 'Position',
                        render: (r) => {
                            return (
                                <span>
                                    <PositionSelect className="w-[150px]" r={r} />
                                </span>
                            )
                        }
                    },
                    {
                        title: 'Jersey',
                        render: (r) =>
                            <span>
                                <JerseySelect className="w-[80px]" r={r} />
                            </span>
                    },
                    {
                        title: 'Level',
                        width: 50,
                        align: 'center',
                        render: (r) => <PlayerLevelTag listPlayerLevel={r?.Player?.ListPlayerLevel} />,
                    },
                    {
                        title: 'Goalie',
                        width: 100,
                        render: (r) =>
                            <span>
                                <GoalieCheckbox r={r} />
                            </span>
                    },
                    {
                        title: 'Captain',
                        width: 100,
                        render: (r) =>
                            <span>
                                <CaptainCheckbox r={r} />
                            </span>
                    },
                    ...(userRoles?.includes('ADMIN') ? [
                        {
                            title: 'Star',
                            width: 100,
                            render: (r) =>
                                <span>
                                    <StarCheckbox r={r} />
                                </span>
                        },
                    ] : []),
                    {
                        title: 'G',
                        width: 50,
                        align: 'right',
                        render: (r) => <SuppressZero value={r.StatGoals} />
                    },
                    {
                        title: 'A',
                        width: 50,
                        align: 'right',
                        render: (r) => <SuppressZero value={r.StatAssists} />
                    },
                    {
                        title: 'PTS',
                        width: 50,
                        align: 'right',
                        render: (r) => <SuppressZero value={r.StatPoints} />
                    },
                    {
                        title: 'PIM',
                        width: 50,
                        align: 'right',
                        render: (r) => <SuppressZero value={r.StatPenaltyMinutes} />
                    },
                    {
                        title: 'Special',
                        width: 150,
                        align: 'center',
                        render: (r) => {
                            if (r.StatGoals >= 3 && r.StatPoints < 4) {
                                return <span className="bg-green-500 text-white rounded px-2 py-1 whitespace-nowrap">HATTRICK!</span>
                            }
                            if (r.StatPoints >= 3) {
                                return <span className="bg-blue-500 text-white rounded px-2 py-1 whitespace-nowrap">{r.StatPoints}+ POINTS!</span>
                            }
                        }
                    },
                    {
                        title: ' ',
                        sortDirections: [],
                        width: 50,
                        className: 'pointer-events-none',
                        render: (record) =>
                            <span className="flex flex-nowrap gap-4 pointer-events-none">
                                <Tooltip title={lineupNotEditableTooltip}>
                                    <a className="flex items-center gap-1 pointer-events-auto" disabled={readonly || isLoading} onClick={(e) => { e.stopPropagation(); if (!readonly) { removeClicked(record); } }}>
                                        <span className="flex items-center gap-1">
                                            {icons.Trash}
                                            Remove
                                        </span>
                                    </a>
                                </Tooltip>
                            </span>
                    }
                ]}
                dataSource={
                    (lineupByTeam[t.teamId] ?? [])
                        .map(l => ({ ...l, key: l.lineupId }))
                }
                pagination={false}
            ></Table>
        </div>

    )

    // const LineupTableMobile = ({ team: t }) => {
    //     return (
    //     )
    // }

    const filterAllowedTeams = (teams) => {
        if (userRoles?.includes('ADMIN')) {
            return teams;
        }
        if (userRoles?.includes('TEAM_MANAGER')) {
            return teams
                .filter(t => managedTeams?.find(mt => mt.teamId == t.teamId) != null);
        }
        return [];
    }

    // const showMobileLineup = useMediaQuery({ query: '(max-width: 1000px)' });

    const lineupNotEditableTooltip = readonly ? 'This game is in FINISHED status and Line up is not editable at this time' : null;

    return (
        <>
            <Confirm
                message={confirmOpen?.message}
                confirmButtonText={confirmOpen?.confirmButtonText}
                confirmButtonLoading={confirmOpen?.confirmButtonLoading}
                confirmButtonType={confirmOpen?.confirmButtonType}
                visible={confirmOpen}
                handleConfirm={confirmOpen?.onOk}
                handleCancel={confirmOpen?.onCancel}
            />

            <LinkRelationModal
                size="large"
                entity={entities.Player}
                data={(unusedActivePlayersByTeam[addPlayersTeamId] ?? [])
                    .filter(p => p.status == null || p.status == 'ACTIVE')
                    .map(p => ({
                        ...p,
                        Jerseys: p.Jerseys.filter(j => j.teamId == addPlayersTeamId)
                    }))
                }
                columns={
                    isMobile ?
                        [
                            entities['Player'].columns['NameWithLogoPositionJersey'],
                        ]
                        :
                        [
                            entities['Player'].columns['NameWithLogo'],
                            entities['Player'].columns['LevelTag'],
                            entities['Player'].columns['Positions'],
                            entities['Player'].columns['Jerseys'],
                        ]
                }
                open={addPlayersOpen}
                onOk={addPlayersOk}
                onCancel={addPlayersCancel}
                okButtonText='Add $number $entities'
            />
    
            {/* <Alert className="mb-4" type="success" message={<span>This game is in <span className="font-bold">FINISHED</span> status and Line up is not editable at this time.</span>} /> */}

            <SegmentedTabs
                block
                items={
                    filterAllowedTeams([data?.HomeTeam, data?.AwayTeam])
                        .filter(t => t != null)
                        .map(t => ({
                            label: (<span className="my-1 flex items-center justify-center gap-2">
                                <EntityLogo entity={t} size={32} />
                                {isMobile ? t.nick : t.name} ({lineupByTeam[t.teamId]?.length ?? 0}{filterAllowedTeams([data?.HomeTeam, data?.AwayTeam]).length == 1 ? ' player' + ((lineupByTeam[t.teamId]?.length ?? 0) != 1 ? 's' : '') : ''})
                            </span>),
                            key: t.name,
                            children:
                                <>
                                    <div className="flex gap-x-2 mt-3 mb-3 items-center">
                                    <Tooltip title={lineupNotEditableTooltip}>
                                        <Button
                                            size="small"
                                            onClick={() => addPlayers(t)}
                                            disabled={readonly || isLoading || (unusedActivePlayersByTeam[t.teamId] ?? []).length == 0}
                                            icon={<PlusOutlined />}
                                        >{isMobile ? 'Add' : 'Add Players'}</Button>
                                    </Tooltip>

                                    <Tooltip title={lineupNotEditableTooltip}>
                                        <Button
                                            size="small"
                                            onClick={() => addAllPlayers(t)}
                                            disabled={readonly || isLoading || (unusedActivePlayersByTeam[t.teamId] ?? []).length == 0}
                                            icon={<PlusOutlined />}
                                        >{isMobile ? 'Add All' : `Add All (${(unusedActivePlayersByTeam[t.teamId] ?? []).length}${lineupByTeam[t.teamId]?.length > 0 ? ' more' : ''})`}</Button>
                                    </Tooltip>

                                    <Tooltip title={lineupNotEditableTooltip}>
                                        <Button
                                            size="small"
                                            onClick={() => removeAllPlayers(t)}
                                            icon={<MinusOutlined />}
                                            disabled={readonly || isLoading || (lineupByTeam[t.teamId] ?? []).length == 0}
                                        >{isMobile ? 'Remove All' : `Remove All (${(lineupByTeam[t.teamId] ?? []).length})`}</Button>
                                    </Tooltip>

                                        <Spin spinning={isLoading || isUpdating} size="small" className="ml-2" />
                                    </div>

                                    {isMobile
                                        ?
                                        <>
                                            {(lineupByTeam[t.teamId] ?? []).length > 0 &&
                                                <div className="relative">
                                                    <Collapse
                                                        className="custom-narrow-collapse"
                                                        items={[...(lineupByTeam[t.teamId] ?? [])]
                                                            .sort((a, b) => compareString(a.Player?.lastName ?? '' + a.Player?.firstName, b.Player?.lastName ?? '' + b.Player?.firstName))
                                                            .map(l => (
                                                                {
                                                                    key: l.lineupId,
                                                                    label: (
                                                                        <span className="pr-[10px] flex items-center justify-center text-[1.0em]">
                                                                            <span className="flex-1">{l.Player?.firstName} {l.Player?.lastName}</span>
                                                                            <span className="flex-shrink-0 flex items-center">
                                                                                {isGameStar(l) &&
                                                                                    <span className="w-[22px] flex justify-center items-center">
                                                                                        <StarIcon className="w-[16px] h-[16px] text-[#1677FF]" />
                                                                                    </span>
                                                                                }
                                                                                {l.ListPosition?.isGoalie == true &&
                                                                                    <span className="w-[22px] flex justify-center items-center">
                                                                                        <GoalieIcon className="w-[16px] h-[16px] text-[#1677FF]" />
                                                                                    </span>
                                                                                }
                                                                                {l.isCaptain &&
                                                                                    <span className="w-[22px] flex justify-center items-center">
                                                                                        <CaptainIcon className="w-[16px] h-[16px] text-[#1677FF]" />
                                                                                    </span>
                                                                                }
                                                                                <span className="inline-block w-[22px] text-center text-[#1677FF]">
                                                                                    {l.ListPosition?.short ?? '-'}
                                                                                </span>
                                                                                <span className="inline-block w-[22px] text-center text-[#1677FF]">
                                                                                    {l.number ?? '-'}
                                                                                </span>
                                                                            </span>
                                                                            <span className="w-[30px] text-right">
                                                                                <Tooltip title={lineupNotEditableTooltip}>
                                                                                    <a className="" disabled={readonly} onClick={(e) => { e.stopPropagation(); if (!readonly) { removeClicked(l); } }}>
                                                                                        <span
                                                                                            className={`ml-1 px-3 py-3 ${!readonly ? 'hover:bg-red-100' : ''}`}
                                                                                        >
                                                                                            <DeleteOutlined />
                                                                                        </span>
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </span>
                                                                        </span>
                                                                    ),
                                                                    children: (
                                                                        <div className="flex gap-4">
                                                                            <EntityLogo entity={l.Player} size={96} circle={false} className="rounded" />
                                                                            <div className="flex flex-col justify-between w-full">
                                                                                <PositionSelect className="w-full" r={l} />
                                                                                <JerseySelect className="w-full" r={l} />
                                                                                <div className="flex gap-2">
                                                                                    <CaptainCheckbox r={l}>Captain</CaptainCheckbox>
                                                                                    {userRoles?.includes('ADMIN') &&
                                                                                        <StarCheckbox r={l}>Star</StarCheckbox>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ),
                                                                }
                                                            ))} />
                                                    <div className={`${isUpdating ? 'absolute' : 'hidden'} inset-0 flex items-center justify-center bg-gray-200 bg-opacity-70 z-10 rounded-lg`}>
                                                    </div>
                                                </div>}
                                            {(lineupByTeam[t.teamId] ?? []).length == 0 &&
                                            <span className='h-16 w-full flex justify-center items-center text-gray-500'>
                                                    {isLoading ? 'Loading...' : 'There are no players in this lineup.'}
                                                </span>
                                            }
                                        </>
                                        : <LineupTableDesktop team={t} />
                                    }
                                </>
                        }))
                }
            />
        </>
    )
}

export default GameLineup;
