import { Radio } from 'antd';
import HmsTableLayout from 'components/HmsTableLayout';
import HmsTableWithFilters from 'components/HmsTableWithFilters';
import useEntities from 'hooks/useEntities';
import useFetchData from 'hooks/useFetchData';
import useOrganization from 'hooks/useOrganization';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import { getImage } from 'utils/getImage';
import icons from 'utils/icons';

const Statistics = () => {
  const entities = useEntities();
  const { useSearchParamSingle } = useSearchParamsMulti();
  const { organization } = useOrganization();
  const ORGANIZATION_ID = organization?.organizationId;

  const columnSets = {
    'standings': {
      name: 'Standings',
      metadata: {
        url: `/admin/tableStandings?organizationId=${ORGANIZATION_ID}&separate=1`,
        entity: entities.Team,
      }
    },
    'players': {
      name: 'Players',
      metadata: {
        url: `/admin/tablePlayersStats?organizationId=${ORGANIZATION_ID}&separate=1`,
        entity: entities.Player,
      }
    },
    'goalies': {
      name: 'Goalies',
      metadata: {
        url: `/admin/tableGoaliesStats?organizationId=${ORGANIZATION_ID}&separate=1`,
        entity: entities.Player,
      }
    },
  };
  const [controllerColumnSet, setControllerColumnSet] = useSearchParamSingle('columnSetKey', Object.keys(columnSets)[0]);
  const currentColumnSet = columnSets?.[controllerColumnSet];
  const { data, isError } = useFetchData(currentColumnSet?.metadata?.url);

  const setColumnSet = (key) => {
    setControllerColumnSet(key);
  }

  const ColumnSetSelector = () => (
    <Radio.Group
      size="small"
      value={controllerColumnSet}
      onChange={(e) => setColumnSet(e.target.value)}
    >
      {Object.entries(columnSets).map(([key, cs]) => (
        <Radio.Button
          key={key}
          value={key}
        >
          {cs.name}
        </Radio.Button>
      ))}
    </Radio.Group>
  )

  const columns = Object.keys(data?.[0] ?? {})
    .filter(key => ['TeamId', 'teamId', 'TeamLogo', 'PlayerId', 'playerId', 'PlayerLogo'].includes(key) === false)
    .map(key => {
      let maxLen = ('' + key).length;
      for (const record of data) {
        maxLen = Math.max(maxLen, ('' + record[key]).length);
      }
      return {
        title: key,
        dataIndex: key,
        width: 90 + maxLen * 6.5,
        sortDirections: [],
        filterType: typeof data?.[0]?.[key] == 'string' ? 'text' : 'number',
      }
    });

  // Add ID, Link and Logo

  if (Object.keys(data?.[0] ?? {}).includes('TeamId')) {
    columns.push({
      title: 'Team Id',
      render: (record) => record?.TeamId,
      visible: false,
      exported: true,
      filterDisabled: true,
    });
    columns.push({
      title: 'Team Link',
      render: (record) => window.location.protocol + '//' + window.location.host + entities.Team.entityUrl({ teamId: record?.TeamId }),
      visible: false,
      exported: true,
      filterDisabled: true,
    });
    columns.push({
      title: 'Team Logo (Medium)',
      render: (record) => getImage({ logoUrl: record?.TeamLogo }, 'md'),
      visible: false,
      exported: true,
      filterDisabled: true,
    });
  }

  if (Object.keys(data?.[0] ?? {}).includes('PlayerId')) {
    columns.push({
      title: 'Player Id',
      render: (record) => record?.PlayerId,
      visible: false,
      exported: true,
      filterDisabled: true,
    });
    columns.push({
      title: 'Player Link',
      render: (record) => window.location.protocol + '//' + window.location.host + entities.Player.entityUrl({ playerId: record?.PlayerId }),
      visible: false,
      exported: true,
      filterDisabled: true,
    });
    columns.push({
      title: 'Player Logo (Medium)',
      render: (record) => getImage({ logoUrl: record?.PlayerLogo }, 'md'),
      visible: false,
      exported: true,
      filterDisabled: true,
    });
  }

  return (
    <HmsTableLayout
      breadcrumbs={[
        { title: 'Home', icon: icons.Home, link: '/' },
        { title: 'Statistics', icon: icons.Statistics },
      ]}
    >
      <HmsTableWithFilters
        filterEnabled={true}
        exportEnabled={true}
        columnSelectorEnabled={true}
        columns={columns}
        extraButtonBarItems={<ColumnSetSelector />}
        exportPrefix={currentColumnSet?.name}
        data={data}
        isError={isError}
        title={'Statistics'}
        fixedX={true}
        fixedY={true}
      />
    </HmsTableLayout>
  )
}

export default Statistics