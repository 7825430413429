import DebugPrinter from "components/Debug/DebugPrinter";
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import useFetchEntities from "hooks/useFetchEntities";
import useFetchEntity from "hooks/useFetchEntity";
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import { useMemo } from "react";
import usePersistentStore from 'store/persistentStore';
import { sortDateAndTime } from "utils/sorters";
import { uniqueByProperty } from "../../utils/oneLiners";
import PhaseSelector from "./Filters/PhaseSelector";
import VenueSelector from "./Filters/VenueSelector";
import TeamStreakRow from "./Parts/TeamStreakRow";

const IG10_GameStreak = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const venue = usePersistentStore((state) => state.get('ig-venue'));
  const setVenue = usePersistentStore((state) => state.set('ig-venue'));
  const size = usePersistentStore((state) => state.get('ig-gamesstreak-size'));
  const setSize = usePersistentStore((state) => state.set('ig-gamesstreak-size'));
  const SIZE_PRESETS = ['LANDSCAPE', 'PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 850, height: 1511, preset: 'PORTRAIT' };

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const groupId = membership?.groupId;

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');

  const venueIdsAll = venue?.venueIds == null || venue?.venueIds.length === 0 ? undefined : venue?.venueIds;

  const { data: games } = useFetchEntities(
    phaseIdsAll != null && 'Game',
    {
      filters: { phaseId: phaseIdsAll, venueId: venueIdsAll },
      extraAttributes: ["HomeTeamGoals", "AwayTeamGoals"],
      relations: 'Venue(name,logoUrl),HomeTeam,AwayTeam',
    }
  );

  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: groupDetail?.Season?.name,
      CompetitionName: groupDetail?.Season?.Competition?.name,
      CompetitionLogo:
        groupDetail?.Season?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
    };
  }, [groupDetail]);

  const data = useMemo(() => {
    if (games == null) return [];
    const allTeams = uniqueByProperty(
      games.map((g) => g.HomeTeam).filter((t) => t),
      "name",
    );

    const gamesByTeam = allTeams.map((team) => ({
      Team: {
        ...team,
        logoUrl: team.logoUrl?.replace("[size]", "cropped_md"),
      },
      games: games.
        sort(
          (g1, g2) => sortDateAndTime(g1.startDate, g1.startTime, g2.startDate, g2.startTime)
        )
        .filter((g) => g.HomeTeam?.nick === team.nick || g.AwayTeam?.nick === team.nick)
        .map((g) => ({
          status: g.status,
          startDate: g.startDate,
          startTime: g.startTime,
          MainTeam: g.HomeTeam?.nick === team.nick ? g.HomeTeam : g.AwayTeam,
          OpponentTeam: g.HomeTeam?.nick === team.nick ? g.AwayTeam : g.HomeTeam,
          MainTeamGoals: g.HomeTeam?.nick === team.nick ? g.HomeTeamGoals : g.AwayTeamGoals,
          OpponentTeamGoals: g.HomeTeam?.nick === team.nick ? g.AwayTeamGoals : g.HomeTeamGoals,
        }))
        .map(g => ({
          ...g,
          MainTeam: g.MainTeam
            ? {
              ...g.MainTeam,
              logoUrl: g.MainTeam.logoUrl?.replace("[size]", "cropped_md"),
            }
            : null,
          OpponentTeam: g.OpponentTeam
            ? {
              ...g.OpponentTeam,
              logoUrl: g.OpponentTeam.logoUrl?.replace("[size]", "cropped_md"),
            }
            : null,
        }))
    }));

    const sorted = gamesByTeam.sort((a, b) => {
      return (a.Team?.nick ?? "").localeCompare(b.Team?.nick ?? "");
    });
    return sorted;
  }, [games]);

  console.log(data)

  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId', 'groupId']}
        />

        <span>Choose venue:</span>
        <div>
          <VenueSelector
            value={venue}
            onChange={(venue) => { setVenue(venue) }}
          />
        </div>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <div />

      </div>

      {showData && (<DebugPrinter data={[groupDetail, games, data, metadata]}></DebugPrinter>)}

      {phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-fit bg-hockey-page-bg p-6 font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <h1 className="text-2xl italic">Games streak</h1>
          <h2 className="font-league text-xl" style={{ color: metadata.GroupColor ?? '#777777' }}>{metadata.GroupName}</h2>
          {data.map((teamStats, index) => (
            <TeamStreakRow
              key={index}
              team={teamStats.Team}
              games={teamStats.games}
            />
          ))}
        </article>
      }
    </>
  );
};

export default IG10_GameStreak;
