import { ConfigProvider } from 'antd';
import Router from './Router';

const theme = {
};

export default function App() {
  return (
    <>
      <ConfigProvider theme={theme}>
        <Router />
      </ConfigProvider>
    </>
  );
}