import useOrganization from 'hooks/useOrganization';
import { Navigate } from 'react-router-dom';
import { prefixRelativeOrg } from 'utils/prefixRelativeOrg';

function OrgNavigate({ to, ...props }) {
    const { organization } = useOrganization();
    const ORGANIZATION_SLUG = organization?.urlSlug;
    const newTo = prefixRelativeOrg(ORGANIZATION_SLUG, to);
    return (
        <Navigate to={newTo} {...props} />

    )
}

export default OrgNavigate