import { Button, Card, message, Spin } from 'antd'
import copy from 'copy-to-clipboard'
import useFetchEntities from 'hooks/useFetchEntities'
import useIsMobile from 'hooks/useIsMobile'
import useOrganization from 'hooks/useOrganization'
import { compareString } from 'utils/comparators'
import icons from 'utils/icons'
import { getAbsoluteUrl } from 'utils/urlFunctions'

const QuickLinks = () => {
  const { organization } = useOrganization();
  const ORGANIZATION_ID = organization?.organizationId;
  const { data: competitions, isLoading } = useFetchEntities('Competition', { filters: { status: 'RUNNING' }, relations: 'Seasons' });
  const [messageApi, messageContextHolder] = message.useMessage();

  const links = [
    {
      name: 'General:',
      links: [
        {
          name: `New Player Registration`,
          url: `/embed/new_player?lang=cs&organizationId=${ORGANIZATION_ID}`,
        }
      ]
    },
    ...(competitions ?? []).map(c => (
      {
        name: <span className="flex items-center gap-1">{icons.Competition}{c.name}</span>,
        links: [
          ...(c.Seasons.filter(s => s.status == 'RUNNING' || s.status == 'FINISHED').sort((a, b) => compareString(a.startDate ?? a.name, b.startDate ?? b.name)).map(s => (
            [
              {
                name: `Games (${s.name}) (CS)`,
                url: `/embed/games?seasonId=${s.seasonId}&lang=cs`,
              },
              {
                name: `Games (${s.name}) (EN)`,
                url: `/embed/games?seasonId=${s.seasonId}&lang=en`,
              },
            ]
          ))).flat(),
          {
            name: `Standings`,
            url: `/embed/standings?lang=cs&competitionIds=${c.competitionId}`,
          },
          {
            name: `Player Statistics`,
            url: `/embed/players_stats?lang=cs&competitionIds=${c.competitionId}`,
          },
          {
            name: `Goalie Statistics`,
            url: `/embed/goalies_stats?lang=cs&competitionIds=${c.competitionId}`,
          },
          {
            name: `Disciplinary`,
            url: `/embed/disciplinaries?lang=cs&competitionId=${c.competitionId}`,
          },
        ],
      }
    )),
  ];

  const isMobile = useIsMobile();

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  const canShare = navigator.share;

  const shareLink = (url, title, text) => {
    const absoluteUrl = getAbsoluteUrl(url);
    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: url,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Web Share API not supported in this browser.');
    }
  };

  const copyLink = (url) => {
    const absoluteUrl = getAbsoluteUrl(url);
    copy(absoluteUrl);
    messageApi.open({
      type: 'success',
      content: `Link copied to clipboard.`,
    });        
  }

  const RecursiveLinks = ({ links }) => (
    <div className="mb-2 flex flex-col">
      {messageContextHolder}

      {links.map((s, idx) => (
        <div key={idx}>
          {s.url && <>
            <span className="flex mr-3">
              <span className={`flex-grow flex truncate ${isMobile ? '' : 'px-2 hover:bg-blue-50'} py-[2px]`}>
                <span className="truncate overflow-ellipsis mr-auto">{s.name}</span>
                {isMobile ?
                    <>
                      <Button size="small" type="default" className="ml-2" onClick={() => openLink(s.url)}>Open</Button>
                      {canShare
                        ?
                          <Button size="small" type="default" className="ml-2" onClick={() => shareLink(s.url, s.name)}>Share</Button>
                        :
                          <Button size="small" type="default" className="ml-2" onClick={() => copyLink(s.url)}>Copy</Button>
                      }
                    </>
                  :
                    <>
                      <a href={s.url} target="_blank" className="ml-3 hover:underline" rel="noreferrer">Open</a>
                      <a target="_blank" className="ml-3 hover:underline" onClick={() => copyLink(s.url)}>Copy link</a>
                    </>
                }
              </span>
            </span>
          </>}
          {s.links && <>
            <div className="mb-2 font-semibold">{s.name}</div>
            <div className="pl-3">
              <RecursiveLinks links={s.links} />
            </div>
          </>}
        </div>
      ))}
    </div>
  )

  return (
    <Card
      size="small"
      className={isMobile ? "w-[calc(100vw-25px)]" : "w-[450px]"}
      title={<div className="text-center font-bold text-lg py-3">Quick Links</div>}
    >
      <div className="pt-2 pb-1">
        {isLoading && <span className="flex items-center justify-center w-full h-14"><Spin /></span>}
        {!isLoading && <RecursiveLinks links={links} />}
      </div>
    </Card>
  )
}

export default QuickLinks