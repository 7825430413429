import EmbedNewPlayerSTART from 'components/Embeds/EmbedNewPlayerSTART';
import EmbedNewPlayerSUCCESS from 'components/Embeds/EmbedNewPlayerSUCCESS';
import OpenReplayTracker from 'components/OpenReplayTracker';
import usePageTitle from 'hooks/usePageTitle';
import React from 'react';

const EmbedNewPlayer = () => {
  const [state, setState] = React.useState('START');
  usePageTitle('New User - HMS');

  const generateBody = () => {
    switch (state) {
      case 'START':
        return <EmbedNewPlayerSTART setState={setState} />;
      case 'SUCCESS':
        return <EmbedNewPlayerSUCCESS setState={setState} />;
    }
  }

  return (
    <>
        <OpenReplayTracker 
          projectKey={process.env.REACT_APP_OPENREPLAY_KEY}
          userId={'Anonymous'} 
          disableSecureMode={process.env.REACT_APP_OPENREPLAY_DISABLE_SECURE_MODE == 'true'} 
          metadata={{ ENVIRONMENT: process.env.REACT_APP_OPENREPLAY_ENVIRONMENT }}
          silent={true} />

      {generateBody()}
    </>
  )
}

export default EmbedNewPlayer