import { useNavigate, useParams } from 'react-router-dom';
import useLoginStore from 'store/loginStore';
import { prefixRelativeOrg } from 'utils/prefixRelativeOrg';

function useOrganization() {
    const navigate = useNavigate();
    const { orgId: orgSlug } = useParams();
    const user = useLoginStore(s => s.user);

    return {
        organization: user?.Organizations?.find(org => org.urlSlug === orgSlug),

        orgNavigate: (toPath) => {
            navigate(prefixRelativeOrg(orgSlug, toPath));
        }
    }
}

export default useOrganization