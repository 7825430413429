import { Button, Select } from "antd";
import TooltipIfTruncated from "components/TooltipIfTruncated";
import { useState } from "react";
import { removeAccents } from "utils/oneLiners";

const GameEventListSelect = ({
    label,
    name,
    options,
    data,
    toggleValueChange: toggleValueChangeParam,
    placeholder,
    workspaceType,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dataXSelectId, _] = useState('x'+Math.random().toString(36).substring(7));

    function toggleValueChange(key, value) {
        if (data?.event?.[key] == value) {
            toggleValueChangeParam(key, null);
        } else {
            toggleValueChangeParam(key, value);
        }
    }

    return (
        <>
            {workspaceType == "mouse" && (
                <div className="grid gap-x-4 grid-cols-10 items-start">
                    <div className="col-span-2 h-8 flex items-center">{label}:</div>
                    <div className="col-span-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                        {placeholder && (
                            <span className="text-gray-400">{placeholder}</span>
                        )}
                        {!placeholder && options.length == 0 && (
                            <span className="text-gray-400">No options</span>
                        )}
                        {!placeholder &&
                            options.length > 0 &&
                            options.map((b, idx) => (
                                <Button
                                    key={idx}
                                    type={data.event[name] == b.value ? "primary" : "default"}
                                    disabled={b.disabled}
                                    className="flex items-center gap-2"
                                    onClick={() => toggleValueChange(name, b.value)}
                                >
                                    <TooltipIfTruncated title={b.label}>
                                        <span>{b.label}</span>
                                    </TooltipIfTruncated>
                                </Button>
                            ))}
                    </div>
                </div>
            )}

            {workspaceType == "keyboard" && (
                <div className={`grid gap-x-4 grid-cols-10 items-start`}>
                    <div className="col-span-2 h-8 flex items-center">{label}:</div>
                    <Select
                        className="col-span-3"
                        value={data.event[name]}
                        onChange={(value) => toggleValueChange(name, value)}
                        placeholder={label}
                        showSearch
                        allowClear
                        onSelect={() => setDropdownOpen(false)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                // do not close dialog on enter but select
                                e.preventDefault();
                                e.stopPropagation();
                            } else if (e.key === "Backspace" && (e.ctrlKey || e.metaKey)) {
                                // Remove the selected item on Ctrl + Delete
                                console.log("Remove selected item");
                                e.preventDefault();
                                toggleValueChange(name, null);
                            } else if (e.key === "Tab") {
                                // if options are open, select the  curently focused option before moving to next field
                                if (dropdownOpen) {
                                    // select active option
                                    const activeOption = document.querySelector(
                                        `.ant-select-item-option-active .${dataXSelectId}`
                                    );
                                    if (activeOption) {
                                        const value = activeOption.getAttribute("data-x");
                                        // this 'if' is needed because there can be no active option when just tabbing through
                                        // this is because of how the toggleValueChnage is implemented ...
                                        if (value != null && data.event[name] != value) toggleValueChange(name, value);
                                        console.log("Select active option and name", value, name);
                                    }
                                }
                            }
                        }}
                        onClear={() => setDropdownOpen(true)}
                        open={dropdownOpen}
                        onFocus={() => setDropdownOpen(true)}
                        onBlur={() => setDropdownOpen(false)}
                        filterOption={(input, option) =>
                            option.customSearchText.indexOf(
                                removeAccents(input).toUpperCase()
                            ) != -1
                        }
                        options={options?.map((b) => ({
                            key: b.value,
                            value: b.value,
                            disabled: b.disabled,
                            customSearchText: removeAccents(b.label.toUpperCase()),
                            label: (
                                <>
                                    <span>{b.label}</span>
                                    <span className={dataXSelectId} data-x={b.value}> </span>
                                </>
                            ),
                        }))}
                    ></Select>
                </div>
            )}
        </>
    );
};

export default GameEventListSelect;
