import useFetchData from 'hooks/useFetchData';
import useOrganization from 'hooks/useOrganization';
import { entitiesDontNeedOrganizationId } from 'utils/apiFunctions';

export default function useFetchEntities (entity, { filters, relations, extraAttributes, onlyAttributes, search, listOnly } = {}) {
  const { organization } = useOrganization();
  const ORGANIZATION_ID = organization?.organizationId;

  filters = filters ?? {};
  if (entitiesDontNeedOrganizationId.indexOf(entity) == -1) {
      filters = { ...filters, 'organizationId': ORGANIZATION_ID };
  }

  const params = new URLSearchParams();
  params.append('entity', entity);
  params.append('relations', relations ?? '');
  params.append('extraAttributes', extraAttributes ?? '');
  params.append('onlyAttributes', onlyAttributes ?? '');
  params.append('search', search ?? '');
  if (listOnly) {
    params.append('listOnly', 'true');
  }
  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined) {
      params.append(key, JSON.stringify(value));
    }
  }

  const { data, status, isLoading, isNotFound, isError, reload } = useFetchData(
      entity
        ? `/admin/entities?${params.toString()}`
        : null
  );

  return { data, status, isLoading, isNotFound, isError, reload };
}