import { BASE_URL } from "./apiFunctions";

export function getImage (record, type = 'sm') {
  if (record?.logoUrl) {
    return record.logoUrl.replace('[size]', 'cropped_' + type);
  }
};

export function getOriginalImage (entity, id) {
  return BASE_URL + `/admin/logo?entity=${entity}&id=${id}&size=original`;
}
