import GameEventEventLocationSelect from 'components/Modals/GameEvent/GameEventEventLocationSelect';
import GameEventGoalLocationSelect from 'components/Modals/GameEvent/GameEventGoalLocationSelect';

const GameEventLocationGroupSelect = ({ data, toggleValueChange }) => (
    <div className="grid gap-x-4 grid-cols-10 items-start">

        <div className="col-span-2 h-8 flex items-center">
            Location:
        </div>

        <div className="col-span-8 flex justify-between">

            <GameEventEventLocationSelect
                data={data}
                toggleValueChange={toggleValueChange}
            />

            <GameEventGoalLocationSelect
                data={data}
                toggleValueChange={toggleValueChange}
            />

        </div>
    </div>
)

export default GameEventLocationGroupSelect;