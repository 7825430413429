import { DeleteOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Slider, Spin, Upload } from 'antd';
import Confirm from 'components/Confirm';
import useIsMobile from 'hooks/useIsMobile';
import useLists from 'hooks/useLists';
import { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import useNotificationStore from 'store/notificationStore';
import { deleteLogo, patchLogo, postLogo } from 'utils/apiFunctions';

const EntityLogoEditorModal = ({ entity, id, srcImage, isOpen, onClose, cropParam, zoomParam }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [downloadedImage, setDownloadedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [modalShown, setModalShown] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [saveBusy, setSaveBusy] = useState(false);
  const canDelete = srcImage != null && srcImage != '' && uploadedImage == null;
  const [confirmOpen, setConfirmOpen] = useState(null);
  const saveDisabled = (uploadedImage ?? srcImage) == null || (srcImage) == '' || croppedAreaPixels == null || croppedAreaPixels.width == null || Number.isNaN(croppedAreaPixels.width);
  const showCropper = modalShown && !downloading;

  const addMessage = useNotificationStore((state) => state.addMessage);
  const { FILE_SIZE_LIMIT } = useLists();
  const isMobile = useIsMobile();

  // Workaround for unintended Ant.Modal close

  const handleMaskMouseDown = (e) => {
    if (e.target.closest('div')?.className?.indexOf('custom-mask-prevent-close') != -1) {
      onClose(false);
    }
  }

  useEffect(() => {
    const mask = document.querySelector('.custom-mask-prevent-close');
    if (mask) {
      mask.addEventListener('mousedown', handleMaskMouseDown);
      return () => {
        mask.removeEventListener('mousedown', handleMaskMouseDown);
      };
    }
  }, [isOpen]);

  // Initialization of the Cropper object

  useEffect(() => {
    if (isOpen) {

      const fetchImage = async (srcImage) => {
        try {
          setDownloading(true);

          const response = await fetch(srcImage, {
            credentials: 'include',
          });
          if (!response.ok) {
            addMessage({ text: 'There was an error dowloading the current image', type: 'error' });
            setDownloading(false);
            return;
          }

          const blob = await response.blob();
          const blobURL = URL.createObjectURL(blob);
          setDownloadedImage(blobURL);

          setDownloading(false);

        } catch (error) {
          addMessage({ text: 'There was an error dowloading the current image', type: 'error' });
          setDownloading(false);
          return;
        }
      };

      // this delay is necessary so that the easy-crop-tool can get the correct height of its container (due to Ant Modal animation)
      setCrop(cropParam ?? { x: 0, y: 0 });
      setZoom(zoomParam ?? 1);
      setUploadedImage(null);
      setDownloadedImage(null);

      if (srcImage && srcImage != '') {
        fetchImage(srcImage);
      }

      const timerId = setTimeout(() => {
        setModalShown(true);
      }, 400);
      return () => {
        clearTimeout(timerId);
      };
    }
    if (!isOpen) {
      setModalShown(false);
      setSaveBusy(false);
    }
  }, [isOpen]);

  // Cropper event handlers

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCancel = () => {
    if (onClose) {
      onClose(false);
    }
  }

  const onOk = async () => {
    const params = {
      cropPixels: JSON.stringify(croppedAreaPixels),
      cropParameters: JSON.stringify({ ...crop, zoom }),
      entity: entity.name,
      id: id,
    };

    setSaveBusy(true);

    try {
      if (uploadedImage) {
        try {
          const blob = await fetch(uploadedImage).then(response => response.blob());

          const result = await postLogo(blob, params);
          if (result.status != 200) {
            addMessage({ text: 'Error saving image: ' + result.response?.message, type: 'error' });
            return;
          }

          if (onClose) {
            onClose(true);
          }

        } catch (error) {
          console.error(error);
          addMessage({ text: 'Error uploading image to server', type: 'error' });
          return;
        }

      } else {

        const result = await patchLogo(params);
        if (result.status != 200) {
          addMessage({ text: 'There was an error resizing the image', type: 'error' });
          return;
        }

        if (onClose) {
          onClose(true, (await result.json()).key);
        }
      }

    } finally {
      setSaveBusy(false);
    }
  }

  const onDelete = async () => {
    if (!confirmOpen) { // prevent fast pressing of enter to cause modal to flicker (pressing enter activates the Remove button before the moda becomes fully visible)
      setConfirmOpen({
        message: <span>Are you sure do you want to permanently delete this image from the server?</span>,
        confirmButtonText: "Delete",
        confirmButtonType: "danger",
        onOk: deleteConfirm,
        onCancel: deleteCancel,
      })
    }
  }

  const deleteCancel = () => {
    setConfirmOpen(null);
  }

  const deleteConfirm = async () => {
    const params = {
      entity: entity.name,
      id: id,
    };

    const result = await deleteLogo(params);
    if (result.status != 200) {
      addMessage({ text: 'There was an error deleting the image', type: 'error' });
      return;
    }
    setConfirmOpen(null);
    onClose(true);
  }

  // File upload to local blob

  const handleBeforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      addMessage({ text: 'You can only upload JPEG or PNG files', type: 'error' });
      return false;
    }

    const reader = new FileReader();
    if (file.size > FILE_SIZE_LIMIT) {
      addMessage({ text: 'File size limit exceeded: ' + FILE_SIZE_LIMIT / 1024 / 1024 + ' MB', type: 'error' });
      return false;
    }
    reader.onload = (e) => {
      const blobURL = URL.createObjectURL(file);
      setUploadedImage(blobURL);
    };
    reader.readAsArrayBuffer(file);

    // reset zoom
    setZoom(1);

    return false;   // Return false to prevent the default behavior of uploading the file to a server
  };

  return (
    <>
      <Confirm
        message={confirmOpen?.message}
        confirmButtonText={confirmOpen?.confirmButtonText}
        confirmButtonLoading={confirmOpen?.confirmButtonLoading}
        confirmButtonType={confirmOpen?.confirmButtonType}
        visible={confirmOpen}
        handleConfirm={confirmOpen?.onOk}
        handleCancel={confirmOpen?.onCancel}
      />

      <Modal
        title="Edit image"
        open={isOpen}
        onCancel={onCancel}
        width={650}
        wrapClassName="custom-mask-prevent-close"
        maskClosable={false}
        footer={
          <span className="flex gap-2">
            <Upload showUploadList={false} withCredentials={true} beforeUpload={handleBeforeUpload} accept="image/png, image/jpeg">
              <Button icon={<UploadOutlined />} disabled={saveBusy}>
                {srcImage || downloadedImage || uploadedImage ? 'Change' : 'Upload'}
              </Button>
            </Upload>
            {canDelete &&
              <Button icon={<DeleteOutlined />} className="custom-danger" onClick={onDelete} disabled={saveBusy || saveDisabled}>
                {!isMobile && 'Delete'}
              </Button>
            }
            <Button className="!ml-auto !mx-0" onClick={onCancel} disabled={saveBusy}>
              Cancel
            </Button>
            <Button type="primary" className={`!mx-0 ${saveBusy ? 'ant-btn-loading' : ''}`} onClick={!saveBusy ? onOk : () => { }} disabled={saveDisabled} loading={false}>
              {saveBusy && <LoadingOutlined spin />}
              Save
            </Button>
          </span>
        }
      >
        <Divider className="m-0" />
        {showCropper &&
          <div className="mx-auto mt-4 mb-2 bg-gray-200 w-full h-[300px] relative">
            <Cropper
              image={uploadedImage ?? downloadedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        }
        {!showCropper &&
          <div className="mx-auto mt-4 mb-2 bg-gray-200 w-full h-[300px] flex items-center justify-center">
            {downloading && <Spin spinning={true} />}
          </div>
        }

        <span className="flex w-full gap-3 items-center mb-2">
          <span className="w-11">
            Zoom:
          </span>
          <Slider
            className="flex-grow"
            min={1}
            max={3}
            step={0.01}
            tooltip={{
              formatter: () => ('' + (zoom.toFixed(2)) + ' x'),
            }}
            onChange={setZoom}
            value={zoom}
          />
          <span className="w-11 text-right">
            {'' + (zoom.toFixed(2)) + ' x'}
          </span>
        </span>

        <Divider className="m-0" />
      </Modal>
    </>
  )
}

export default EntityLogoEditorModal
